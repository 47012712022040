import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, CircularProgress } from '@material-ui/core';

import { AppTheme } from '../../styles/AppTheme';
import { AppConfig } from '../../AppConfig';
import { CustomAppButtonStyles } from '../../styles/CustomStyles';

export function AppButton(props) {
  const buttonStyle = css([Styles.button, props.buttonStyle])
  const textStyle = css([Styles.buttonText, props.buttonTextStyle])
  const iconContainerStyle = css([Styles.iconContainer, props.iconContainerStyle])
  const iconStyle = css([Styles.icon, props.iconStyle])
  const progressIconStyle = css([Styles.progressIcon, props.progressIcon])
  const progressSize = props.progressIconSize ? props.progressIconSize : 24
  return (
    <Button
      onClick={props.onClick}
      disableRipple
      disabled={props.disabled || props.inProcess}
      className={buttonStyle}>
      {props.icon &&
        <div className={iconContainerStyle}>
          <i className={`material-icons-outlined ${iconStyle}`}>{props.icon}</i>
        </div>
      }
      {!props.inProcess &&
        <p className={textStyle}>{props.buttonText}</p>
      }
      {props.inProcess &&
        <CircularProgress size={progressSize} className={progressIconStyle} />
      }
    </Button>
  )
}
const Styles = (AppConfig.CUSTOM_STYLE) ? CustomAppButtonStyles : StyleSheet.create({
  button: {
    backgroundColor: AppTheme.buttonBg,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    height: 45,
    minWidth: 120,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.buttonTextColor
  },
  buttonText: {
    fontSize: 16,
    margin: 0,
    textTransform: 'none',
    color: AppTheme.tintContrastColour,
    fontWeight: 'bold',
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  progressIcon: {
    color: '#FFFFFF',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  }
})
