import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { CustomCbDefFieldsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';

export class CbDefFieldsCommon extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('cbdeffieldscommon')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.itemId = this.props.match.params.id
    this.entityAttrName = 'fieldscommon'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('blocks'), to: this.baseRoute() + '/cbdefs' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['cbdefs', this.itemId], queryParams: { pb: 'fieldDefFields' } },
    ]
    this.tableHeadersMap = {
      Order: (current) => { return current.order },
      'Key / Label': (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.id}</span>
            <br/>
            <span className={css(Styles.spanValue)}>{current.label}</span>
          </div>
        )
      },
      Type: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.iType}</span>
          </div>
        )
      },
      Mandatory: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.mandatory}</span>
          </div>
        )
      },
      // Status: (current) => {
      //   return (
      //     <div>
      //       {UiHelper.chipLive(current.state)}
      //     </div>
      //   )
      // }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDefFields
    this.setState({
      items: Helper.sortDictArray(Helper.dictToArray(results[0].fieldscommon || {}))
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['cbdefs', this.itemId], { }),
    })
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['cbdefs', this.itemId], { }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['cbdefs', this.itemId], { }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefFieldsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})