import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Tab, Tabs } from '@material-ui/core';
import { LineChartCard, PieChartCard } from './ChartCard';
import { AppTheme } from '../../../styles/AppTheme';
export class TabChartCard extends Component {
  constructor(props) {
    super(props);
  }

  handleTabChange = (event, tab, {chartType}) => {
    if(this.props.selectedTabData && this.props.handleTabChange) {
      this.props.handleTabChange(tab, chartType)
    }
  };

  render() {
    const {chartType, tabsData, chartData, xLabelsDifference, selectedTabData } = this.props

    if(!chartType || !tabsData || !chartData || !selectedTabData) {
      return null
    }

    return (
      <div className={css(Styles.outerContainer)}>
        <Tabs value={selectedTabData[chartType]}
          onChange={(event, tab) => this.handleTabChange(event, tab, {chartType})}
          variant='fullWidth'
          TabIndicatorProps={{ style: { display: 'none' }}}
          className={css(Styles.tabs)} >
          {
            tabsData[chartType].map((chart, index)=> {
              return(<Tab key={chart.reportType} disableRipple label={
                <div className={css(Styles.tabLabelContainer)}>
                  <span className={css(Styles.tabLabel1)}>{chart.title}</span>
                  <span className={css(Styles.tabLabel2)}>{chart.total}</span>
                </div>
              } className={css(Styles.tab, selectedTabData[chartType] === index && Styles.selectedTab)}/>)
            })
          }
        </Tabs>
        {(chartType === 'lineChart') && (<LineChartCard item={{
          data: chartData,
          xLabelsDifference}} isTabChart={true}/>)}
        {(chartType === 'pieChart') && (<PieChartCard item={{
          data: chartData}} isTabChart={true}/>)}
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    border: '1px solid ' + AppTheme.borderColor,
    borderRadius: AppTheme.borderRadius,
  },
  tabs: {
    height: '80px',
    backgroundColor: AppTheme.secondaryColor,
    borderTopLeftRadius: AppTheme.borderRadius,
    borderTopRightRadius: AppTheme.borderRadius,
  },
  tab: {
    height: '80px',
    backgroundColor: AppTheme.secondaryColor,
    borderTopLeftRadius: AppTheme.borderRadius,
    borderTopRightRadius: AppTheme.borderRadius,
    borderBottom: '1px solid ' + AppTheme.borderColor,
  },
  selectedTab: {
    backgroundColor: 'white',
    color: AppTheme.buttonBg,
    borderBottom: 0,
  },
  tabLabelContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabLabel1: {
    textTransform: 'capitalize',
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  tabLabel2: {
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  }
})