import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppStyles } from '../../../styles/AppTheme';

export class CoItems extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('cos')
    this.orgId = this.props.match.params.orgId
    this.typeId = this.props.match.params.id
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('cotypes'), to: this.baseRoute() + '/cos' },
      { title: Helper.getString('cos') },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cos'], { typeId: this.typeId, pb: 'fieldDef'}) },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cotypes', this.typeId], {pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => current.order,
      Info: (current) => UiHelper.itemDetails(current)
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    // this.headerActions = [
    //   {
    //     label: 'Refresh',
    //     icon: 'refresh',
    //     onClick: () => this.fetchItems()
    //   },
    //   {
    //     label: 'New Custom Oject',
    //     onClick: () => this.openModalForAdd()
    //   },
    // ]
  }

  onFetchSuccess(results) {
    console.log('CoItems:onFetchSuccess:', results)
    this.fieldDef = results[0].fieldDef
    const fields = []
    this.nestedFields = []
    this.fieldDef.steps[0].fields.forEach(i => {
      if(i.subType === 'NESTED_OBJECT') {
        this.nestedFields.push(i)
      } else {
        fields.push(i)
      }
    })
    console.log('CoItems:nestedFields:', this.nestedFields)
    this.fieldDef.steps[0].fields = fields
    this.formDefinition = this.fieldDef
    this.setState({
      items: Helper.sortDictArray(results[0].items),
    })
    if(results[1].title) {
      this.pageTitle = results[1].title
    }
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiData: { orgId: this.orgId, typeId: this.typeId},
      apiEndPoint: ApiHelper.makeUrlPath(['cos']),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['cos', current.id]),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['cos', current.id], {}),
    });
  }

  onActions(current) {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    this.nestedFields.forEach(i => {
      actions.push(
        <Link
          to={this.baseRoute() + '/cos/' + this.typeId + '/items/' + current.id + '/subitems' + '?nestedattr=' + i.key}
          key={current.id + '-fields'}>
          <Tooltip title={i.label} placement='top-start' key={current.id + 'editTT'}>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>dataset</i>
            </span>
          </Tooltip>
        </Link>
      )
    })
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})