import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppStyles } from '../../styles/AppTheme';
import { AppConfig } from '../../AppConfig';
import { CustomCbDefsStyles } from '../../styles/CustomStyles';

export class CbDefs extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.pageTitle = options.pageTitle || Helper.getString('cbdefs')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('cbdefs') },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['items'], queryParams: { groupId: 'cbdeftag' + 'PRODUCT', pb: 'fieldDef' } }
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Title: (current) => {
        return UiHelper.itemTitleImage(current)
      },
      Info: (current) => {
        return (
          <div>
            {/* <span className={css(Styles.spanValue)}>{current.id}</span>
            <br/> */}
            <span className={css(Styles.spanValue)}>{Object.keys(current.fields || {}).length + ' fields'}</span>
            <br/>
            <span className={css(Styles.spanValue)}>{Object.keys(current.fieldscommon || {}).length + ' fieldscommon'}</span>
            <br/>
            <span className={css(Styles.spanValue, Styles.line2)}>{current.cbstyle} {current.singleton === 'YES' ? '| SINGLETON' : ''}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'cbdefs'
  }

  // onFetchSuccess(results, options) {
  //   const { fetchNext } = options || {}
  //   const prodData = results[0]
  //   this.fieldDef = prodData.fieldDef
  //   this.formDefinition = prodData.fieldDef
  //   this.handleNextpage(results[0])
  //   this.setState({
  //     items: fetchNext ? this.state.items.concat(results[0].items) : results[0].items
  //   })
  // }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    console.log('results[1]:', results[1])
    this.tags = results[1]
    if(this.orgId === 'PRODUCT') {
      UiHelper.populateOptions(this.formDefinition, this.tags, 'tags')
    }
    this.setState({
      items: Helper.sortDictArray(results[0].items)
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdefs'], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdefs', current.id], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdefs', current.id], {}),
    });
  }

  // openModalForAdd(fieldDef) {
  //   const fields = []
  //   this.fieldDef.steps[0].fields.forEach(i => { if (i.key === 'title') fields.push(i) } )
  //   super.openModalForAdd({ steps: [ {fields}]})
  // }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/cbdefs/' + current.id} key={current.id + '-fields'}>
        <Tooltip title='Widget Item Fields' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>format_list_numbered</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(
      <Link to={this.baseRoute() + '/cbdefs/' + current.id + '/fieldscommon'} key={current.id + '-fieldscommon'}>
        <Tooltip title='Widget Item Common Fields' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>checklist_rtl</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})