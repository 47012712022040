import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppStyles } from '../../styles/AppTheme';
import { CustomCbDefsReadStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppHelper } from '../../helpers/AppHelper';

export class CbDefsRead extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('cbdefs')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: this.orgId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      'Slug / ID': (current) => {
        return UiHelper.itemIdContainer(current)
      },
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            <br/>
            <span className={css(Styles.spanValue)}>{Object.keys(current.fields || {}).length + ' fields'}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.noAdd = true
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    this.setState({
      items: Helper.sortDictArray(results[0].items)
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(
      <Link to={this.baseRoute() + '/cbdats/' + current.id} key={current.id + '-cbdats'}>
        <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>dataset</i>
          </span>
        </Tooltip>
      </Link>
    )
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsReadStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})