import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Dialog from '@material-ui/core/Dialog';
import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { CustomBlockSelectionDialogStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import Grid from '@material-ui/core/Grid';
import { Helper } from '../helpers/Helper';
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { AppButton } from './AppButton';
import { TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';


export class CbSelection extends Component {
  constructor(props) {
    super(props);
    this.tagsData = {}
    this.props.masterTagsList && this.props.masterTagsList.forEach(tagData => {
      if (tagData.id) {
        this.tagsData[tagData.id] = { title: tagData.title || '', id: tagData.id }
      }
    })
    this.state = {
      selectedBlock: this._getDefaultBlock(),
      inProcess: true,
      searchTerm: '',
      selectedBlockItems: {
        items: []
      },
      selectedTags: [],
      masterTagsList: this._getDefaultTags(),
      openBlockSelector:false
    }
    this.cbSource = this.props.cbSource
  }

  _getDefaultTags() {
    const blk = this._getDefaultBlock()
    if(blk === 'SITE') {
      return this.filterTagsOnAvability(this.props.cbSite)
    }
    if(blk === 'TENANT') {
      return this.filterTagsOnAvability(this.props.cbOrg)
    }
    return this.props.masterTagsList || []
  }

  filterTagsOnAvability(cb) {

    const tagIds = []
    const tagList = []
    if (!cb || !cb.items || cb.items.length === 0) return tagList
    cb.items.forEach(widget => {
      if (widget.tags && widget.tags.length > 0) {
        widget.tags.forEach(tagId => {
          if (tagIds.indexOf(tagId) === -1) {
            tagIds.push(tagId)
          }
        });
      }
    });

    tagIds.forEach(tagId => {
      tagList.push({ id: tagId, title: this.tagsData[tagId].title })
    })

    return tagList
  }

  selectDefaultTag() {
    if(!this.state.masterTagsList) return
    for (const tagData of this.state.masterTagsList) {
      // Making 'banner' tag as default selection
      if(tagData.id === 'cbdeftagPRODUCT-7623039728') {
        this.setState({selectedTags:[tagData.id]},()=>{
          this.filterDataOnTags('')
        })
        break
      }
    }
  }

  onCloseModal() {
    this.props.onClosed();
  }

  selectedTile = (key, item) => {
    this.setState({
      selectedBlockSource: key,
      selectedBlockType: item.id,
      selectedBlockItem: item,
      inProcess: false,
    })
    if (this.state.selectedBlock === 'GLOBAL') {
      this.setState({
        globalBlocksImgView: true
      })
    } else {
      this.props.doActionNext(key, item.id, item)
    }
  }

  onBlockChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      inProcess: true,
      globalBlocksImgView: false,
      selectedBlockType: '',
      searchTerm: '',
      selectedTags: []
    }, () => this.setItems());
  }

  blockSelector = () => {
    const fieldParams = {
      fullWidth: true,
      value: this.state.selectedBlock,
      name: 'selectedBlock'
    };
    return (
      <FormControl margin='normal' className={css(Styles.formControlConatiner)}
        onKeyDown={(e)=>{
          if(e.key === 'ArrowDown') {
            this.setState({openBlockSelector:false})
          }
        }}>
        <Select
          {...fieldParams}
          onChange={(e) => this.onBlockChange(e)}
          open={this.state.openBlockSelector}
          onClick={()=>this.setState({openBlockSelector:!this.state.openBlockSelector})}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            getContentAnchorEl: null,
            className: css(Styles.menuProps),
            classes: {
              paper: css(Styles.paper)
            }
          }}
          input={<Input disableUnderline={true} />}
          inputProps={{
            classes: {
              root: css(Styles.textInputStyles)
            }
          }}
          className={css(Styles.formControl)}>
          {this.cbSource && this.cbSource.options.map(item => {
            return (
              <MenuItem value={item.key} key={item.key} className={css(Styles.selectMenuItemContainer)}>
                <p className={css(Styles.menuItem)}>{item.label}</p>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  renderTags(item) {
    return item.tags &&
      this.tagsData &&
      item.tags.map(
        (tagId) =>
          this.tagsData[tagId] && (
            <span className={css(Styles.widgetTag, this.isTagSelected(tagId) && Styles.tagSelected)}
              onClick={(e) => {
                e.stopPropagation()
                this.handleTagsSelection(tagId)
              }}
            >
              {this.tagsData[tagId].title}
            </span>
          )
      )
  }

  globalBlocks = () => {
    if (this.state.selectedBlockItems.items && this.state.selectedBlockItems.items.length > 0) {
      return (
        <div className={css(Styles.blockContainer)}>
          {this.state.globalBlocksImgView ?
            <div className={css(Styles.globalBlocksImgViewContainer)}>
              {this.state.selectedBlockItem && this.state.selectedBlockItem.title &&
                <p className={css([Styles.title, Styles.titleSpec])}>{this.state.selectedBlockItem.title}</p>
              }
              {this.state.selectedBlockItem.cbstyle && (
                <p className={css(Styles.cbStyle,Styles.cbStyleSelectedBlock)}>
                  {this.state.selectedBlockItem.cbstyle}
                </p>
              )}
              <div className={css(Styles.tagsContainerWidget,Styles.blockTags)}>
                {this.renderTags(this.state.selectedBlockItem)}
              </div>
              <div className={css(Styles.imgOuterContainer)}>
                <img
                  src={(this.state.selectedBlockItem && this.state.selectedBlockItem.image) || require('../../assets/images/cb-dummy.jpg')} //TODO remove the mock image data
                  width='418'
                  height='250'
                  alt={Helper.getString('blockSelectionAlt')}
                  className={css(Styles.imageContent)} />
              </div>
            </div>
            :
            <Grid container spacing={2}>
              {this.state.selectedBlockItems.items.map((item, index) => {
                if ((item.state && item.state !== 'LIVE' && AppConfig.SHOW_ALL_CB === 'NO')) return
                const tileContainerStyle = item.id === this.state.selectedBlockType ? css(Styles.tileContainer, Styles.tileSelectedContainer) : css(Styles.tileContainer)
                return (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                    <div id={item.id} className={tileContainerStyle} onClick={() => this.selectedTile(this.state.selectedBlock, item)}>
                      <p className={css(Styles.title, Styles.tileTitle)}>{item.title}</p>
                      {item.cbstyle && <p className={css(Styles.cbStyle)}>{item.cbstyle}</p>}
                      <div className={css(Styles.tagsContainerWidget)}>
                        {this.renderTags(item)}
                      </div>
                      <div className={css(Styles.imgContainer)}>
                        <img
                          src={item.image || require('../../assets/images/cb-dummy.jpg')} //TODO remove the mock image data
                          width='418'
                          height='200'
                          alt={Helper.getString('blockSelectionAlt')}
                          className={css(Styles.imageContent)} />
                      </div>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          }
        </div>
      )
    } else {
      return (
        <div className={css(Styles.emptyContainer)}>
          <p className={css(Styles.title)}>{Helper.getString('noItems')}</p>
        </div>
      )
    }
  }

  blocks = () => {
    if (this.state.selectedBlockItems.items.length > 0) {
      return (
        <div className={css(Styles.blockContainer)}>
          <Grid container spacing={2}>
            {this.state.selectedBlockItems.items.map((item, index) => {
              if ((item.state && item.state !== 'LIVE' && AppConfig.SHOW_ALL_CB === 'NO')) return
              const tileContainerStyle = item.id === this.state.selectedBlockType ? css(Styles.tileContainer2, Styles.tileSelectedContainer) : css(Styles.tileContainer2)
              return (
                <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                  <div id={item.id} className={tileContainerStyle} onClick={() => this.selectedTile(this.state.selectedBlock, item)}>
                    <div className={css(Styles.blockTitleContainer)}>
                      <div className={css([Styles.imgContainer, Styles.imgContainerVariant])}>
                        <img
                          src={item.image || require('../../assets/images/cb.jpg')}
                          width='418'
                          height='200'
                          alt={Helper.getString('blockSelectionAlt')}
                          className={css(Styles.imageContent)} />
                      </div>
                      {/* Keep this comment for testing and checking */}
                      <p className={css([Styles.title, Styles.titleVariant])}>{item.title}</p>
                      {/* <div className={css(Styles.tagsContainerWidget, Styles.tagsContainerWidgetBlocks)}>
                        {this.renderTags(item)}
                      </div> */}
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
      )
    } else {
      return (
        <div className={css(Styles.emptyContainer)}>
          <p className={css(Styles.title)}>{Helper.getString('noItems')}</p>
        </div>
      )
    }
  }

  blockDisplay = () => {
    if (this.state.selectedBlock === 'GLOBAL') {
      return this.globalBlocks()
    } else if (this.state.selectedBlock === 'TENANT') {
      return this.blocks()
    } else if (this.state.selectedBlock === 'SITE') {
      return this.blocks()
    }
  }

  scrollToId = () => {
    const element = document.getElementById(this.state.selectedBlockItem.id)
    element && element.scrollIntoView()
  }

  doActionBack = () => {
    this.setState({
      globalBlocksImgView: false
    }, () => this.scrollToId())
  }

  handleInputChange = (event) => {
    const userInput = event.target.value;

    this.setState({
      searchTerm: userInput,
      selectedTags: []
    }, () => {
      this.filterDataOnTags(userInput)
    })
  }

  clearSearch() {
    this.setState({
      searchTerm: '',
    }, () => {
      this.filterDataOnTags(this.state.searchTerm)
    })
  }

  clearTagsSelection() {
    this.setState({
      selectedTags: [],
    }, () => {
      this.filterDataOnTags(this.state.searchTerm)
    })
  }

  clearAll() {
    this.setState({
      searchTerm: '',
      selectedTags: [],
    }, () => {
      this.filterDataOnTags(this.state.searchTerm)
    })
  }

  searchComponent = () => {
    return (
      <div className={css(Styles.searchFieldContainer)} style={this.props.hideBlockSelector ? { margin: 0, marginRight: 30 } : { margin: 0, marginRight: 10 }}>
        <TextField
          variant={'standard'}
          placeholder='Search'
          value={this.state.searchTerm}
          className={css(Styles.formControl, Styles.formControlSearch)}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textInputStyles),
              input: css(Styles.formInputStyle),
            },
            // endAdornment: <ClearIcon
            //   className={css(Styles.inputClearIcon)}
            //   onClick={this.clearSearch.bind(this)}
            // />
          }}
          autoComplete='off'
          onChange={this.handleInputChange} />
        <span className={css(Styles.clearAll)} onClick={this.clearAll.bind(this)}>{Helper.getString('clearAll')}</span>
      </div>
    )
  }

  componentDidMount() {
    this.setItems()
  }

  _getDefaultBlock() {
    if(!this.props.selectedBlock || this.props.selectedBlock === 'GLOBAL') return 'GLOBAL'
    if (
      this.props &&
      this.props.cbSite &&
      this.props.cbSite.items &&
      this.props.cbSite.items.length === 0
    ) {
      return 'GLOBAL'
    }
    return this.props.selectedBlock || 'GLOBAL'
  }

  setItems = () => {
    if (this.state.selectedBlock === 'GLOBAL') {
      this.setState({
        selectedBlockItems: this.props.cbGlobal,
        itemsOrginal: this.props.cbGlobal,
        masterTagsList: Helper.sortAscending(this.props.masterTagsList || [], 'title')
      }, () => this.selectDefaultTag())
    } else if (this.state.selectedBlock === 'TENANT') {
      this.setState({
        selectedBlockItems: this.props.cbOrg,
        itemsOrginal: this.props.cbOrg,
        masterTagsList: Helper.sortAscending(this.filterTagsOnAvability(this.props.cbOrg) || [], 'title')
      }, () => this.selectDefaultTag())
    } else if (this.state.selectedBlock === 'SITE') {
      this.setState({
        selectedBlockItems: this.props.cbSite,
        itemsOrginal: this.props.cbSite,
        masterTagsList: Helper.sortAscending(this.filterTagsOnAvability(this.props.cbSite) || [], 'title')
      }, () => this.selectDefaultTag())
    }
  }

  handleTagsSelection(tagId) {
    if(this.state.globalBlocksImgView) {
      this.setState({globalBlocksImgView:false})
    }

    const temp = [...this.state.selectedTags]

    if (temp.length > 0) {
      temp.pop()
      temp.push(tagId)
    } else{
      temp.push(tagId)
    }

    this.setState({ selectedTags: temp, searchTerm: '' }, () => {
      this.filterDataOnTags(this.state.searchTerm)
    })
  }

  isTagSelected(tagId) {
    return this.state.selectedTags.includes(tagId)
  }

  filterDataOnTags(userInput) {
    let items = this.state.itemsOrginal.items
    let masterTagsSrearchList = []

    // tags and search both present
    if(this.state.selectedTags.length > 0 && userInput) {
      masterTagsSrearchList = this.state.selectedTags
      items = items.filter(item => {
        return (item.tags && masterTagsSrearchList.some(tag => item.tags.includes(tag))) && (item.title.toLowerCase()).includes(userInput.toLowerCase())
      })
    }

    // Only search present
    if(this.state.selectedTags.length === 0 && userInput) {
      // console.log('Only search present')
      const relTags = []
      for (const tagId in this.tagsData) {
        const tagData = this.tagsData[tagId];
        if (tagData.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1) {
          relTags.push(tagId)
        }
      }
      masterTagsSrearchList = relTags
      items = items.filter(item => {
        return (
          item.title.toLowerCase().includes(userInput.toLowerCase()) ||
          (item.cbstyle && item.cbstyle.toLowerCase().includes(userInput.toLowerCase())) ||
          (item.tags &&
            masterTagsSrearchList.some((tag) => item.tags.includes(tag)))
        );
      })
    }

    // Only tag present
    if(this.state.selectedTags.length > 0 && !userInput) {
      // console.log('Only tag present')
      masterTagsSrearchList = this.state.selectedTags
      items = items.filter(item => {
        return (item.tags && masterTagsSrearchList.some(tag => item.tags.includes(tag)))
      })
    }

    //  No tags and no search present
    if (this.state.selectedTags.length === 0 && !userInput) {
      // console.log('No tags and no search present')
      items = this.state.itemsOrginal.items
    }

    this.setState({
      selectedBlockItems: {
        items: items
      }
    })
  }

  onKeyDown(e) {

    const modalContainer = document.getElementById('modalContainer')
    if(!modalContainer)return

    if(e.key === 'ArrowDown') {
      modalContainer.scrollBy({
        behavior:'smooth',
        top:100
      })
      return
    }
    if(e.key === 'ArrowUp') {
      modalContainer.scrollBy({
        behavior:'smooth',
        top:-100
      })
      return
    }
    if(e.key === 'Escape') {
      this.onCloseModal()
    }
  }

  render() {
    const dialogHeading = this.props.title
    return (
      <Dialog
        open={this.props.isOpen}
        // aria-labelledby={this.props.formDefinition.steps[0].title} //TODO enable the uncommented code
        fullWidth={true}
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
        }}
        fullScreen={true}
        onKeyDown={this.onKeyDown.bind(this)}
      >
        <div className={css(Styles.modalContainer)}>
          <div className={css(Styles.searchAndTagsContainer)}>
            <div className={css(Styles.modalTitle)}>{dialogHeading}</div>
            {this.state.masterTagsList.length > 0 && this.state.selectedBlock !== 'TENANT' &&
                <div className={css(Styles.tagsOuterContainer)}>
                  {/* <div className={css(Styles.clearTags)} onClick={this.clearTagsSelection.bind(this)}>
                    <span className={css(Styles.clearFilterText)}>{Helper.getString('clearTags')}</span>
                    <ClearIcon className={css(Styles.clearFilterIcon)} />
                  </div> */}
                  <div className={css(Styles.tagsContainer)}>
                    {this.state.masterTagsList.map((tag, idx) => (
                      <span
                        className={css(
                          Styles.tag,
                          this.isTagSelected(tag.id) && Styles.tagSelected
                        )}
                        onClick={() => this.handleTagsSelection(tag.id)}
                        key={tag.id}
                      >
                        {tag.title}
                      </span>
                    ))}
                  </div>
                </div>
            }
          </div>


          <div className={css(Styles.modalOuterContainer)}>
            <div className={css([Styles.modalHeader, AppStyles.modalHeader])}>
              <div className={css(Styles.modalHeaderContent)}>
                <div className={css(Styles.modalSearchContainer)}>
                  {!this.state.globalBlocksImgView  && this.searchComponent()}
                </div>
                {!this.props.hideBlockSelector && this.blockSelector()}
              </div>

              <button
                className={css(Styles.modalCloseButton)}
                onClick={this.onCloseModal.bind(this)}
              >
                <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
                  <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
                </svg>
              </button>
            </div>
            <div className={css(Styles.searchAndTagsouterContainer)}>
              <div className={css(Styles.widgetsContainer)}>
                <div className={css(Styles.modalContent)} id='modalContainer'>
                  {this.state.selectedBlockItems && this.blockDisplay()}
                </div>
                {this.state.globalBlocksImgView && (
                  <div className={css(Styles.actions)}>
                    <div
                      className={css(Styles.backButton)}
                      onClick={() => this.doActionBack()}
                    >
                      {'Back'}
                    </div>
                    <div className={css(Styles.actionsNext)}>
                      <AppButton
                        buttonStyle={Styles.saveButton}
                        disabled={this.state.inProcess}
                        onClick={() =>
                          this.props.doActionNext(
                            this.state.selectedBlockSource,
                            this.state.selectedBlockType
                          )
                        }
                        buttonText={'Next'}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomBlockSelectionDialogStyles : StyleSheet.create({
  modalHeader: {
    // backgroundColor: 'whitesmoke',
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 14,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalHeaderContent: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  modalTitle: {
    color: '#222222',
    fontSize: 26,
    fontWeight: 600,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  modalContent: {
    display: 'flex',
    // flex: 1,
    overflowY: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-width: 767px)': {
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    '@media (max-width: 767px)': {
      top: 23,
      right: 15,
    },
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius / 2
  },
  customRoot: {
    zIndex: 13000
  },
  customPaper: {
    borderRadius: AppTheme.borderRadius,
    height: '100%',

    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  customPaperMd: {
    maxWidth: 'unset'
  },
  customPaperFullWidth: {
    width: '100vw'
  },
  blockTitleContainer: {
    minHeight: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '0px 10px'
  },
  imgContainerVariant: {
    height: 100
  },
  titleVariant: {
    padding: 0
  },
  blockContainer: {
    paddingBottom: 25,
    width: '100%'
  },
  globalBlocksImgViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  imgOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  imgContainer: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    overflow: 'hidden',
    padding: 15,
  },
  imageContent: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    overflow: 'hidden',
    borderRadius: 16,
    border: '1.5px solid whitesmoke',
  },
  title: {
    color: '#222222',
    fontSize: 16,
    // fontWeight: 600,
    margin: 0,
    padding: '15px 15px 0px'
  },
  titleSpec: {
    textAlign: 'center',
    padding: 15
  },
  tileContainer: {
    cursor: 'pointer',
    border: '1px solid #FFFFFF',//#DFE4EA',
    borderRadius: AppTheme.borderRadius,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // padding: 15,
    ':hover': {
      border: '1px solid #A9A9A9', //#c9cdd1
      boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
      transition: '.3s'
    }
  },
  tileContainer2: {
    cursor: 'pointer',
    border: '1px solid #F5F5F5',//#DFE4EA',
    borderRadius: AppTheme.borderRadius,
    height: '100%',
    // padding: 15,
    ':hover': {
      border: '1px solid #A9A9A9', //#c9cdd1
      boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
      transition: '.3s'
    }
  },
  tileSelectedContainer: {
    border: '2px solid' + AppTheme.primaryColor,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 30,
    padding: '0px 30px',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      padding: '0px 15px',
    }
  },
  actionsNext: {
    // flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  saveButton: {
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    height: 54,
    minWidth: 150,
    padding: 13,
    fontSize: 16,
    textTransform: 'capitalize',
    color: AppTheme.secondaryBg
  },
  backButton: {
    marginRight: 50,
    cursor: 'pointer',
    color: AppTheme.primaryColor
  },
  marginLeft: {
    color: 'white'
  },

  menuProps: {
    zIndex: 130000,
    marginTop: 5
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
    borderRadius: AppTheme.borderRadius,
    padding: '0px 10px'
  },
  formControlConatiner: {
    margin: 0,
    marginRight: 40,
    minWidth: 230,
    '@media (max-width: 767px)': {
      marginTop: 10,
      marginRight: 0,
    }
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid ' + AppTheme.borderColor,
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    ':focus': {
      backgroundColor: '#ffffff'
    }
  },
  selectMenuItemContainer: {
    padding: '5px 10xp',
    borderRadius: AppTheme.borderRadius,
  },
  menuItem: {
    fontSize: 16,
    color: '#222222',
    margin: '6px 0px'
  },
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px 0px',
    height: '100%',
    flex: 1
  },
  searchFieldContainer: {
    // margin: 0,
    // marginRight: 10,
    '@media (max-width: 767px)': {
      marginTop: 10,
      marginRight: 0,
    }
  },
  tagsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    margin: '10px 0'
  },
  tag: {},
  inputClearIcon: {
    cursor: 'pointer',
    color: '#000000',
    fontSize: 21
  },
  tagsOuterContainer: {
  },
  clearTags: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 15
  },
  clearFilterText: {
    fontSize: 15,
    fontWeight: 500
  },
  clearFilterIcon: {
    color: '#000000',
    fontSize: 14,
    marginLeft: 5
  },
  cbStyle:{
    fontSize:10,
    paddingLeft:15,
    margin:0,
    marginTop:5,
    color: '#7e7e7e'
  },
  cbStyleSelectedBlock:{
    margin:'0 auto 15px auto',
  }
})
