import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { CustomCbDefFieldsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppStyles } from '../../styles/AppTheme';
import { CbSelection } from '../../common/components/CbSelection';
import { Headerbar } from '../../common/components/Headerbar';
import { AppUiHelper } from '../../helpers/AppUiHelper';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';
import { AppHelper } from '../../helpers/AppHelper';

export class CbOrgs extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('cborgs')
    this.orgId = this.props.match.params.orgId
    this.groupId = 'cborg' + this.orgId
    this.itemId = this.groupId + '-all'
    this.entityAttrName = 'items'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('blocks') },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['items', this.itemId], queryParams: { groupId: this.groupId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: 'PRODUCT', pb: 'fieldDef' } },
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['cotypes'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['forms'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'], { groupId: 'cbdeftagPRODUCT', pb: 'fieldDef' }) }
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Title: (current) => {
        return (
          <div className={css(Styles.titleContainer)}>
            <div className={css(Styles.imageContainer)}>
              {current._image ?
                <img src={current._image}
                  className={css(Styles.sectionImage)} /> :
                <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
              }
            </div>
            {current.title}
          </div>
        )
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'cborgs'
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    this.cbSource = this.formDefinition.steps[0].fields.find((o) => o.key === 'cbSource')

    this.cbGlobal = results[1]
    this.cbGlobalById = Helper.itemsToMap(this.cbGlobal.items)
    UiHelper.populateOptions(this.formDefinition, this.cbGlobal, 'cbGlobalId')

    this.cbOrg = results[2]
    this.cbOrgById = Helper.itemsToMap(this.cbOrg.items)
    UiHelper.populateOptions(this.formDefinition, this.cbOrg, 'cbTenantId')
    const items = Helper.sortDictArray(Helper.dictToArray(results[0].items))
    items.forEach(i => {
      const options = {
        orgId: this.orgId,
        widgetId: i.id,
        widgetGlobalId: i.cbGlobalId || i.cbTenantId,
      };
      i._cbScopeId = AppHelper.resuableWidgetScopeLink(options)
      if(i.cbGlobalId) {
        i._singleton = this.cbGlobalById[i.cbGlobalId].singleton
        i._image = this.cbGlobalById[i.cbGlobalId].image || null
      } else if(i.cbTenantId) {
        i._singleton = this.cbOrgById[i.cbTenantId].singleton
      }
    })

    this.coTypes = results[3]
    this.forms = results[4]

    this.setState({
      items,
      masterTagsList:results[5].items || []
    })
  }

  // renderAboveTable() {
  //   return (
  //     <div>
  //       <p>Configure reusable widgets (with the associated data) to make page creation easier</p>
  //     </div>
  //   )
  // }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.formDefinition.disableAlwaysFormFields = ['cbSource', 'cbTenantId', 'cbGlobalId', 'cbSiteBlockId']
    if (this.state.selectedBlockSource === 'GLOBAL') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').value = this.state.selectedBlockType
    } else if (this.state.selectedBlockSource === 'TENANT') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').value = this.state.selectedBlockType
    } else if (this.state.selectedBlockSource === 'SITE') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').value = this.state.selectedBlockType
    }

    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').value = this.state.selectedBlockSource
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').defaultValue = this.state.selectedBlockSource
    // if(this.cbOrg.items.length <= 0) {
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    // } else {
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = false
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = false
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = false
    // }
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
    })
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    actions.push(
      <Tooltip title='Duplicate' placement='top-start' key={current.id + 'Duplicate'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => { this.openModalForActions(current)}}>copy_all</i>
        </span>
      </Tooltip>
    )
    if(current._singleton === 'YES') {
      actions.push(
        <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'
              onClick={() => AppUiHelper.handleSingletonWidgetData(this, current)}>dataset</i>
          </span>
        </Tooltip>
      )
    } else {
      actions.push(
        <Link to={this.baseRoute() + '/cbdats2/' + current._cbScopeId} key={current.id + '-cbdats'}>
          <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>dataset</i>
            </span>
          </Tooltip>
        </Link>
      )
    }
    return actions
  }

  openModalForActions(current) {
    console.log('edit:', current);
    this.formDefinition = AppUiHelper.getFieldDefForDuplication()
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['miscs', 'widget-shared-duplication'], { orgId: this.orgId }),
      apiData: {
        orgId: this.orgId,
        groupId: `cborg${this.orgId}`,
        mainItemId: `cborg${this.orgId}-all`,
        itemId: current.id
      },
      modalOpen: true
    });
  }

  renderHeaderbar() {
    const title = this.pageTitle ? this.pageTitle : null
    const pageKey = this.pageKey ? this.pageKey : null
    const actions = [{
      label: 'Add new',
      onClick: () => this.setState({ blockSelectionModal: true })
    }]
    if (this.export) {
      actions.push({
        label: 'Export',
        icon: 'file_download',
        onClick: () => this.onExport()
      })
    }
    if (this.conf && this.conf.previewPath) {
      actions.push({
        tooltip: Helper.getString('preview'),
        icon: 'desktop_windows',
        type: 'icon',
        onClick: () => this.openNewWindow()
      })
    }
    return (
      <Headerbar
        pageKey={pageKey}
        title={title} actions={actions}
        actionComponents={this.state.headerActionComponents}
        breadCrumbs={this.breadCrumbs}
      />
    )
  }

  doActionNext = (selectedBlockSource, selectedBlockType) => {
    this.setState({
      blockSelectionModal: false,
      selectedBlockSource: selectedBlockSource,
      selectedBlockType: selectedBlockType
    }, () => this.openModalForAdd())
  }

  renderBelowTable() {
    return this.state.blockSelectionModal ? this.renderBlockSelectionModal() : ''
  }


  renderBlockSelectionModal() {
    return (
      <CbSelection
        title={Helper.getString('blockSelection')}
        isOpen={this.state.blockSelectionModal}
        onClosed={() => this.setState({ blockSelectionModal: false })}
        cbOrg={this.cbOrg}
        cbGlobal={this.cbGlobal}
        cbSource={this.cbSource}
        doActionNext={this.doActionNext}
        page={this.page}
        masterTagsList={this.state.masterTagsList}
      />
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefFieldsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})