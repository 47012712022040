import React, { useEffect, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import Dialog from '@material-ui/core/Dialog';
import { AppConfig } from '../../AppConfig';
import { AppButton } from './AppButton';
import { CustomColourPickerStyles } from '../../styles/CustomStyles';
import { Helper } from '../helpers/Helper';
import { UiHelper } from '../helpers/UiHelper';
import { CircularProgress } from '@material-ui/core';

const DEFAULT_COLOUR = '#FFFFFF'

export const ColourPicker = (props) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const [colour, setColour] = useState(props.value || props.defaultValue || DEFAULT_COLOUR);


  const [brandColors, setBrandColors] = useState([])

  useEffect(() => {
    props.handleColorChange(colour, props.id)
  }, [])

  useEffect(() => {
    setBrandColors(props.colorPickerData.data)
  }, [props.colorPickerData.data])

  const actionColourTile = (col, index) => {
    return (
      <div
        key={index}
        className={css([Styles.colourTile, Styles.colourTileQuickPick])}
        style={{ backgroundColor: col.code }}
        onClick={() => setColour(col.code)}
      >
        {UiHelper.renderTransparentTileBycolorCode(col.code)}
      </div>
    )
  }

  const displayColourTile = (col) => {
    return (
      <div
        className={css([Styles.colourTile, Styles.displayColourTile])}
        style={{ backgroundColor: col }}
      >
        {UiHelper.renderTransparentTileBycolorCode(col)}
      </div>
    )
  }

  const renderPickerModal = () => {
    return (
      <Dialog
        open={isOpen}
        aria-labelledby={'colourPicker'}
        fullWidth={true}
        fullScreen={window.innerWidth && window.innerWidth < 480 ? true : false}
        classes={{
          root: css(Styles.customRoot),
          paper: css(Styles.customPaper),
        }}>
        <div className={css([Styles.modalHeader])}>
          <div className={css(Styles.modalTitle)}>{Helper.getString('colourPickerTitle')}</div>
          <button className={css(Styles.modalCloseButton)} onClick={() => {
            toggle(false)
            setColour(props.value)
          }}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        </div>
        <div className={css(Styles.modalContent)}>
          <div className={css(Styles.popOver)} ref={popover}>
            <HexColorPicker className={css(Styles.picker)} color={colour} onChange={(e) => setColour(e)} />
            <div className={css(Styles.coloursContainer)}>
              <div className={css(Styles.selectedColour)}>
                <HexColorInput className={css(Styles.input)} color={colour} onChange={(e) => setColour(e)} prefixed alpha />
                {displayColourTile(colour)}
              </div>
              <p className={css(Styles.title)}>{Helper.getString('brandColours')}</p>
              <div className={css(Styles.brandColourContainer)}>
                {props.colorPickerData.isLoading ? <CircularProgress className={css(Styles.spinner)}/> :
                  brandColors.map((col, index) => {
                    return actionColourTile(col, index)
                  })}

              </div>
            </div>
          </div>
          <div className={css(Styles.actions)}>
            <AppButton
              buttonText={Helper.getString('colourPickerBtnTitle')}
              onClick={() => {
                props.handleColorChange(colour, props.id)
                toggle(false)
              }}
            />
          </div>
        </div>
      </Dialog>
    )
  }
  return (
    <div className={css(Styles.pickerContainer)}>
      <div
        className={css([Styles.colourTile, Styles.swatch2])}
        style={{ backgroundColor: props.value }}
        onClick={() => toggle(true)}
      >
        {UiHelper.renderTransparentTileBycolorCode(props.value)}
      </div>
      <div className={css(Styles.closeIconContainer)} onClick={() => toggle(true)}>
        <i class='material-icons-outlined' style={{ fontSize: 12 }}>colorize</i>
      </div>
      {isOpen &&
        renderPickerModal()
      }
    </div>
  );
};

const Styles = AppConfig.CUSTOM_STYLE ? CustomColourPickerStyles : StyleSheet.create({
  pickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'flex-start'
  },
  input: {
    marginRight: 10,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    padding: '11px 20px',
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    height: 27,
    outline: 'none'
  },
  popOver: {
    borderRadius: 9,
    display: 'flex',
    backgroundColor: '#FFFFFF',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  colourTile: {
    width: 51,
    height: 51,
    borderRadius: 16,
    border: '1px solid #e5e5e5',
    margin: 5,
    cursor: 'pointer',
    overflow: 'hidden'
  },
  colourTileQuickPick: {
    width: 40,
    height: 40,
    borderRadius: 10,
  },
  swatch2: {
    margin: 0,
    marginRight: 5
  },
  displayColourTile: {
    cursor: 'default'
  },
  customRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
    zIndex: 13000
  },
  customPaper: {
    borderRadius: 40,
    maxWidth: 800,
    backgroundColor: AppTheme.secondaryBg,
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '@media (max-width: 767px)': {
      borderRadius: 0,
    },
  },
  picker: {
    width: 300,
    height: 300,
    '@media (max-width: 767px)': {
      width: 300,
      height: 300,
    }
  },
  selectedColour: {
    display: 'flex',
    alignItems: 'center'
  },
  coloursContainer: {
    marginLeft: 30,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 767px)': {
      marginTop: 30,
      marginLeft: 0
    },
  },
  modalCloseButton: {
    position: 'absolute',
    top: 33,
    right: 30,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 18,
    height: 18
  },
  modalHeader: {
    padding: 25,
    paddingBottom: 10
  },
  modalTitle: {
    color: 'black',
    fontSize: 26,
    fontWeight: 600
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#e5e5e5',
    marginBottom: 20,
    marginTop: 10
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 30
  },
  brandColourContainer: {
    display: 'flex'
  },
  closeIconContainer: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid black',
    padding: 2,
    borderRadius: 4,
    margin: '2px 0px',
  },
  modalContent: {
    paddingLeft: 25,
    paddingRight: 25
  },
  title: {
    //marginTop: 10
  },
  spinner:{
    margin:'0 auto'
  }
})
