import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { AppTheme } from '../../../styles/AppTheme';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { Helper } from '../../../common/helpers/Helper';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { CommonTheme } from '../../../styles/Styles';
import { Controller } from '../../../common/Controller';
import { AppConfig } from '../../../AppConfig';
import TableCard from '../../../common/components/tables/TableCard';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TabChartCard } from '../../../common/components/charts/TabChartCard';

const vsprintf = require('sprintf-js').vsprintf

function generateColor(fraction) {
  return `rgba(90, 140, 245, ${(fraction * fraction).toFixed(2)})`
}

function getPieChartColor(key, index, numKeys) {
  return generateColor(Number(index + 1) / numKeys)
}

function fillMissingReportData(data, count, dimension, defaultValues) {
  const newData = [];
  for (let i = 0; i < count; i++) {
    const found = data.find(item => parseInt(item[dimension]) === i);
    newData.push(found ? {...found, [dimension]: i.toString()} : { [dimension]: i.toString(), ...defaultValues });
  }
  return newData;
}

function filterReportDataTillCurrentHour(data) {
  const currentHour = new Date().getHours();
  return data.filter(item => parseInt(item.nthHour) <= currentHour);
}

function reportsToLineData(reports, reportType, fieldMapping, label) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  let labels = report.data.map(b => b[fieldMapping.key]);
  if(fieldMapping.timeFrame) {
    if(fieldMapping.timeFrame === 'today') {
      report.data = fillMissingReportData(report.data, 24, fieldMapping.key, fieldMapping.defaultValues)
      report.data = filterReportDataTillCurrentHour(report.data)
      labels = Helper.getHoursIn12HourFormat()
    } else if(fieldMapping.timeFrame === 'yesterday') {
      report.data = fillMissingReportData(report.data, 24, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.getHoursIn12HourFormat()
    } else if(fieldMapping.timeFrame === 'lastWeek') {
      report.data = fillMissingReportData(report.data, 7, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.generatePreviousMonthsOrDays('days', 7)
    } else if(fieldMapping.timeFrame === 'lastMonth') {
      report.data = fillMissingReportData(report.data, 30, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.generatePreviousMonthsOrDays('days', 30)
    } else if(fieldMapping.timeFrame === 'lastQuarter') {
      report.data = fillMissingReportData(report.data, 90, fieldMapping.key, fieldMapping.defaultValues)
      labels = Helper.generatePreviousMonthsOrDays('days', 90)
    }else if(fieldMapping.timeFrame === 'lastSixMonths') {
      report.data = fillMissingReportData(report.data, 7, fieldMapping.key, fieldMapping.defaultValues)
      report.data = report.data.slice(-6);
      labels = Helper.generatePreviousMonthsOrDays('months', 6)
    }else if(fieldMapping.timeFrame === 'lastYear') {
      report.data = fillMissingReportData(report.data, 13, fieldMapping.key, fieldMapping.defaultValues)
      report.data = report.data.slice(-12);
      labels = Helper.generatePreviousMonthsOrDays('months', 12)
    }
  }

  return {
    labels: labels,
    datasets: [
      {
        label,
        data:  report.data.map(b => b[fieldMapping.count]),
        backgroundColor: report.data.map((b, i) => generateColor(Number(i + 1) / Number(report.data.length))),
        borderWidth: 2,
        borderColor: 'rgba(90, 140, 245, 1)',
        segment: {
          borderDash: ctx => {
            return ((ctx.p1DataIndex === report.data.length - 1) && (fieldMapping.timeFrame !== 'yesterday')) ? [2,2] : []
          }
        },
      },
    ],
  }
}

function reportsToPieData(reports, reportType,fieldMapping, label) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0 || !Array.isArray(report.data)) {
    return {}
  }

  const slicedData = report.data.slice(0, 10)
  const reportsKeyOrder = slicedData.map(item => item[fieldMapping.key])
  const reportKeys = reportsKeyOrder.reduce((p, c) => p.concat(slicedData.filter(b => b[fieldMapping.key] === c)), [])
  const numProgressiveBucketKeys = Number(reportKeys.length)
  return {
    labels: slicedData.map(b => b[fieldMapping.key]),
    datasets: [
      {
        label,
        data: slicedData.map(b => b[fieldMapping.count]),
        backgroundColor: slicedData.map((b, i) => getPieChartColor(b[fieldMapping.key], slicedData.length - 1 - i, numProgressiveBucketKeys)),
        borderWidth: 1,
      },
    ],
  }
}

function reportsToTopSourcesTableData(reports, reportType) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  const customReport =  report.data.sort((a, b) => b.activeUsers - a.activeUsers).slice(0, 10)
    .map(item => ({
      pageReferrer: item.pageReferrer ? Helper.removeQueryParams(item.pageReferrer) : 'Direct / None',
      activeUsers: item.activeUsers || 0,
    }))
  return {
    headers: ['Source', 'Visitors'],
    rows: customReport.map(item => Object.values(item))
  }
}

function reportsToTopUTMSourcesTableData(reports, reportType) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  const customReport =  report.data.sort((a, b) => b.activeUsers - a.activeUsers).slice(0, 10)
    .map(item => ({
      utmSource: item.firstUserManualSource,
      activeUsers: item.activeUsers || 0,
    }))
  return {
    headers: ['UTM Source', 'Visitors'],
    rows: customReport.map(item => Object.values(item))
  }
}

function reportsToLandingPagesTableData(reports, reportType) {
  const report = reports.find(report => report.key === reportType) || {};
  if(!report || Object.keys(report).length === 0) {
    return {}
  }
  const customReport =  report.data.sort((a, b) => b.screenPageViews - a.screenPageViews).slice(0, 10)
    .map(item => ({
      landingPage: item.landingPage,
      pageViews: item.screenPageViews || 0,
      userEngagementDuration: Math.floor(item.userEngagementDuration / (item.activeUsers || 1)) || 0,
      bounceRate: Math.floor((item.bounceRate || 0) * 100) + '%',
      users: item.totalUsers || 0,
      new: (Math.floor((item.newUsers / item.totalUsers) * 100) || 0) + '%',
      active: (Math.floor((item.activeUsers / item.totalUsers) * 100) || 0) + '%',
    }))
  return {
    headers: ['Landing Page', 'Page Views', 'Duration (seconds)', 'Bounce Rate', 'Users', 'New', 'Active'],
    rows: customReport.map(item => Object.values(item))
  }
}

export class DashboardOrg extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params.orgId
    this.pageTitle = 'Dashboard'
    this.pageKey = 'orgDashboard'
    this.startDate = 'lastMonth'
    this.mauMap = {
      today: 'nthHour',
      yesterday: 'nthHour',
      lastWeek: 'nthDay',
      lastMonth: 'nthDay',
      lastQuarter: 'nthDay',
      lastSixMonths: 'nthMonth',
      lastYear: 'nthMonth',
    }
    this.timeFrames = [
      {
        key: 'today',
        label: 'Today'
      },
      {
        key: 'yesterday',
        label: 'Yesterday'
      },
      {
        key: 'lastWeek',
        label: 'Last 7 days'
      },
      {
        key: 'lastMonth',
        label: 'Last 30 days'
      },
      {
        key: 'lastQuarter',
        label: 'Last 90 days'
      },
      {
        key: 'lastSixMonths',
        label: 'Last 6 months'
      },
      {
        key: 'lastYear',
        label: 'Last 12 months'
      },
    ]
    this.xLabelsDifference = {
      today: 2,
      yesterday: 2,
      lastWeek: 1,
      lastMonth: 4,
      lastQuarter: 6,
      lastSixMonths: 1,
      lastYear: 2,
    }
    this.chartTabsData = {
      lineChart: [
        {
          reportType: 'activeUsersByTimeInterval',
          title: 'Active Users',
          count: 'activeUsers',
          label: 'Users',
          defaultValues: {activeUsers: '0'},
        },
        {
          reportType: 'screenPageViewsByTimeInterval',
          title: 'Total Pageviews',
          count: 'screenPageViews',
          label: 'Pageviews',
          defaultValues: {screenPageViews: '0'},
        },
      ],
      pieChart: [
        {
          reportType: 'activeUsersByCountry',
          title: 'Active Users By Country',
          key: 'country',
          count: 'activeUsers',
          label: 'Users',
        },
        {
          reportType: 'activeUsersByDeviceCategory',
          title: 'Active Users By Device',
          key: 'deviceCategory',
          count: 'activeUsers',
          label: 'Users',
        },

      ]
    }
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['orgs', this.orgId], { pb: 'fieldDef' }) },
      { ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'], queryParams: { orgId: this.orgId, startDate: this.startDate } },
    ]
    this.noAdd = true
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef;
    this.state.analyticsReports = results[1].reports || [];
    this.analyticsReportTypes = ['lineChart', 'pieChart', 'activeUsersByPageReferrer', 'activeUsersByFirstUserManualSource', 'pageMetricsByLandingPage']
    this.state.startDate = this.startDate
    this.state.timeFrameSelected = this.timeFrames.find(timeFrame => timeFrame.key === this.startDate)
    this.org = results[0]
    this.pageTitle = 'Welcome ' + this.org.title.toLocaleLowerCase() + ','
    this.state.selectedTabData = {
      lineChart: 0,
      pieChart: 0,
    }
  }

  banner() {
    return(
      <div className={css(Styles.bannerOuterContainer)}>
        <div className={css(Styles.bannerTextContainer)}>
          <h2 className={css(Styles.bannerTitle)}>{Helper.getString('adminBannerTitle')}</h2>
          <p className={css(Styles.bannerDesc)}>{Helper.getString('adminBannerDesc')}</p>
          {/* <AppButton TODO: commented for now
            buttonStyle={[CommonStyles.buttonStyle, Styles.buttonStyle]}
            buttonTextStyle={[CommonStyles.buttonTextStyle, Styles.buttonTextStyle]}
            // onClick={() => this.getSupport()}
            buttonText={Helper.getString('getStarted')}/> */}
        </div>
        <div className={css(Styles.bannerImgOuterContainer)}>
          <div className={css(Styles.bannerImgContainer)}>
            <img src={require('../../../assets/images/bannerImg.svg')} className={css(Styles.imageContent)} alt={Helper.getString('adminBannerAlt')}/>
          </div>
        </div>
      </div>
    )
  }

  quickListItems() {
    let l1Tiles = []
    if(Controller.get().roleHelper().canEditOrg()) {
      l1Tiles = [
        { title: Helper.getString('cats'), link: vsprintf('/admin/orgs/%s/cats', [this.orgId]) },
        { title: Helper.getString('products'), link: vsprintf('/admin/orgs/%s/products', [this.orgId]) },
        { title: Helper.getString('lists'), link: vsprintf('/admin/orgs/%s/lists', [this.orgId]) },
        { title: Helper.getString('orders'), link: vsprintf('/admin/orgs/%s/orders', [this.orgId]) },

        { title: Helper.getString('pages'), link: vsprintf('/admin/orgs/%s/pages', [this.orgId]) },
        { title: Helper.getString('blocks'), link: vsprintf('/admin/orgs/%s/cborgs', [this.orgId]) },
        // { title: Helper.getString('blogtags'), link: vsprintf('/admin/orgs/%s/btags', [this.orgId]) }, //TODO: added all link here
        // { title: Helper.getString('bloglists'), link: vsprintf('/admin/orgs/%s/blists', [this.orgId]) },
        { title: Helper.getString('blogs'), link: vsprintf('/admin/orgs/%s/blogs', [this.orgId]) },

        // { title: Helper.getString('settings'), link: vsprintf('/admin/orgs/%s/settings', [this.orgId]) },
        { title: Helper.getString('admins'), link: vsprintf('/admin/orgs/%s/users', [this.orgId]) }
      ]
    } else if(Controller.get().roleHelper().hasCurrentOrgRole('BLOGGER')) {
      l1Tiles = [
        { title: Helper.getString('blogs'), link: vsprintf('/admin/orgs/%s/blogs', [this.orgId]) },
      ]
    } else if(Controller.get().roleHelper().hasCurrentOrgRole('CRM')) {
      l1Tiles = [
        { title: Helper.getString('crm-contacts'), link: vsprintf('/admin/orgs/%s/crm-contacts', [this.orgId]) },
        // { title: Helper.getString('crm-contacts'), link: vsprintf('/admin/orgs/%s/crm-contacts', [this.orgId]) },
      ]
    }
    return(
      <Grid container spacing={2}>
        {l1Tiles.map(tile => {
          return (
            <Grid item xs={6} sm={4}> {/* TODO: grid style changed since stats tiles commented */}
              <Link to={tile.link} className={css(Styles.link)}>
                <div className={css(Styles.quickListItemsContainer)}>
                  <p className={css(Styles.quickListTitleContainer)} >{tile.title}</p>
                </div>
              </Link>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  contactBanner() {
    return(
      <div className={css(Styles.bannerOuterContainer, Styles.contactBannerOuterContainer)}>
        <div className={css(Styles.bannerTextContainer, Styles.contactBannerTextContainer)}>
          <p className={css(Styles.contactBannerTitle)}>{Helper.getString('contactBannerTitle')}</p>
          <div className={css(Styles.contactUsBannerTitleContainer)}>
            {/* <Link target='_blank' to={AppConfig.SLIXTA_WEB + 'contact'} className={css(Styles.link)}> */}
            <h2 className={css(Styles.contactUsBannerTitle)}>{Helper.getString('contactUsForSupport')}</h2>
            {/* </Link> */}
            <div className={css(Styles.imageHandClickContainer)}>
              <img src={require('../../../assets/images/hand-click.svg')}
                className={css(Styles.imageContent)} alt={Helper.getString('handClickImgAlt')}/>
            </div>
          </div>
        </div>
        <div className={css(Styles.bannerImgOuterContainer)}>
          <div className={css(Styles.contactBannerImgContainer)}>
            <img src={require('../../../assets/images/contact-banner.svg')} className={css(Styles.imageContent)} alt={Helper.getString('adminBannerAlt')}/>
          </div>
        </div>
      </div>
    )
  }

  onAutoCompleteChange = (value) => {
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      timeFrameSelected : value,
      startDate: value.key,
    })
    ApiHelper.callAwait({
      ms:  AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-web-analytics'],
      queryParams: { orgId: this.orgId, startDate: value.key },
    }).then(result => {
      this.setState({
        analyticsReports: result.reports || [],
        fetchState: ApiHelper.State.READY,
      })
    }).catch(err => {
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: Helper.getErrorMsg(err)
      })
    })
  }

  handleTabChange = (tab, chartType) => {
    this.setState({
      selectedTabData: {
        ...this.state.selectedTabData,
        [chartType]: tab
      }
    })
  }

  renderBelowTable() {
    if(this.state.analyticsReports && this.state.analyticsReports.length > 0) {
      return(
        <div className={css(Styles.outerContainer)}>
          <div className={css(Styles.analyticsDashboardContainer)}>
            <div className={css(Styles.analyticsContainer)}>
              <div className={css(Styles.dateRangeContainer)}>
                <FormControl className={css(Styles.formControlMainFilters)} variant='outlined'>
                  <Autocomplete
                    value={this.state.timeFrameSelected}
                    options={this.timeFrames}
                    onChange={(e, val) => this.onAutoCompleteChange(val)}
                    getOptionLabel={(option) => option.label || option.text}
                    getOptionSelected={(option, value) => option.key === value.key}
                    disableClearable
                    renderInput={params =>
                      <TextField
                        {...params}
                        variant={'standard'}
                        className={css(Styles.formControlFilters)}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          classes: {
                            root: css([Styles.textInputStyles, Styles.autoCompleteInputRoot]),
                            input: css(Styles.formInputStyle, Styles.textInput),
                          },
                        }} />}
                  />
                </FormControl>
              </div>
            </div>
            <div className={css(Styles.analyticsContainer, Styles.analyticsDataContainer)}>
              {
                this.analyticsReportTypes.map((analyticReportType, index)=>{

                  if(Object.keys(this.chartTabsData).includes(analyticReportType)) {
                    this.chartTabsData[analyticReportType].forEach((tab, index)=>{
                      const report = this.state.analyticsReports.find(report => report.key === tab.reportType) || {};
                      const total = (report.total || Number(report.total) === 0) ? report.total : undefined;
                      tab.total = total;
                    })
                  }

                  if(analyticReportType === 'lineChart') {
                    const chartData = reportsToLineData(this.state.analyticsReports,
                      this.chartTabsData.lineChart[this.state.selectedTabData.lineChart].reportType,
                      {key: this.mauMap[this.state.startDate], count: this.chartTabsData.lineChart[this.state.selectedTabData.lineChart].count, timeFrame: this.state.startDate, defaultValues: this.chartTabsData.lineChart[this.state.selectedTabData.lineChart].defaultValues},
                      this.chartTabsData.lineChart[this.state.selectedTabData.lineChart].label)

                    const xLabelsDifference = this.xLabelsDifference[this.state.startDate];

                    return ( <div className={css(Styles.lineStatItem)} key={index}>
                      <TabChartCard chartType={analyticReportType} tabsData={this.chartTabsData} chartData={chartData} xLabelsDifference={xLabelsDifference} selectedTabData={this.state.selectedTabData}  handleTabChange={this.handleTabChange}/>
                    </div>)
                  } else if(analyticReportType === 'pieChart') {
                    const chartData = reportsToPieData(this.state.analyticsReports,
                      this.chartTabsData.pieChart[this.state.selectedTabData.pieChart].reportType,
                      {key: this.chartTabsData.pieChart[this.state.selectedTabData.pieChart].key, count: this.chartTabsData.pieChart[this.state.selectedTabData.pieChart].count},
                      this.chartTabsData.pieChart[this.state.selectedTabData.pieChart].label)

                    const xLabelsDifference = this.xLabelsDifference[this.state.startDate];

                    return ( <div className={css(Styles.lineStatItem)} key={index}>
                      <TabChartCard chartType={analyticReportType} tabsData={this.chartTabsData} chartData={chartData} xLabelsDifference={xLabelsDifference} selectedTabData={this.state.selectedTabData}  handleTabChange={this.handleTabChange}/>
                    </div>)
                  } else if(analyticReportType === 'activeUsersByPageReferrer') {
                    return(
                      <div className={css(Styles.tableStatGridItem)} key={index}>
                        <TableCard item={{data: reportsToTopSourcesTableData(this.state.analyticsReports,'activeUsersByPageReferrer'), title: 'Top Sources'}}/>
                      </div>
                    )
                  } else if(analyticReportType === 'activeUsersByFirstUserManualSource') {
                    return(
                      <div className={css(Styles.tableStatGridItem)} key={index}>
                        <TableCard item={{data: reportsToTopUTMSourcesTableData(this.state.analyticsReports,'activeUsersByFirstUserManualSource'), title: 'Top UTM Sources'}}/>
                      </div>
                    )
                  } else if(analyticReportType === 'pageMetricsByLandingPage') {
                    return(
                      <div className={css(Styles.tableStatItem)} key={index}>
                        <TableCard item={{data: reportsToLandingPagesTableData(this.state.analyticsReports,'pageMetricsByLandingPage'), title: 'Landing Pages'}}/>
                      </div>
                    )
                  }
                  return ''
                })
              }
            </div>
          </div>
        </div>
      )
    }
    return(
      <div className={css(Styles.outerContainer)}>
        {this.banner()}
        <div className={css(Styles.innerContainer)}>
          {/* <div className={css(Styles.firstContainer)}> TODO: stats commented for now
            <StatsInfo />
          </div> */}
          <div className={css(Styles.secondContainer)}>
            <div className={css(Styles.quickContainer)}>
              <p className={css(Styles.quickContainerTitle)}>{Helper.getString('quickLinks')}</p>
              {this.quickListItems()}
            </div>
            <div className={css(Styles.contactBannerContainer)}>
              {this.contactBanner()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    marginTop: 40,
    marginBottom: 40,
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  bannerOuterContainer: {
    display: 'flex',
    minHeight: 223,
    backgroundColor: '#E2EAFC',
    borderRadius: AppTheme.borderRadius,
    '@media (max-width: 767px)': {
      flexDirection: 'column-reverse'
    }
  },
  bannerTextContainer: {
    flex: 1,
    padding: 30,
    '@media (max-width: 768px)': {
      padding: 20,
    }
  },
  bannerImgOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bannerImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 400,
    height: 280,
    marginTop: -60,
    paddingRight: 30,
    zIndex: 1000,
    '@media (max-width: 768px)': {
      width: 300,
      height: 200,
      paddingRight: 0,
    }
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  bannerTitle: {
    margin: 0,
    color: CommonTheme.textColor,
    fontSize: 36,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: 30,
    }
  },
  bannerDesc: {
    margin: 0,
    fontSize: 18,
    color: CommonTheme.textColor,
    marginTop: 15,
    '@media (max-width: 768px)': {
      fontSize: 15,
    }
  },
  buttonStyle: {
    padding: 18,
    marginTop: 30,
    minWidth: 180
  },
  buttonTextStyle: {
    fontSize: 16,
    lineHeight: '19px',
    textTransform: 'capitalize'
  },
  firstContainer: {
    flex: .55,
    '@media (max-width: 768px)': {
      flex: 1,
    }
  },
  secondContainer: {
    flex: 1,
    marginLeft: 0,
    '@media (max-width: 768px)': {
      flex: 1,
      marginLeft: 0,
      marginTop: 30
    }
  },
  quickContainer: {
    border: CommonTheme.containerBorderColor,
    borderRadius: AppTheme.borderRadius,
    padding: '20px 30px',
    '@media (max-width: 768px)': {
      padding: '20px 15px',
    }
  },
  quickContainerTitle: {
    textTransform: 'capitalize',
    fontSize: 20,
    color: CommonTheme.textColor,
    fontWeight: 500,
    margin: 0,
    marginBottom: 30
  },
  link: {
    textDecoration: 'none'
  },
  quickListItemsContainer: {
    border: CommonTheme.containerBorderColor,
    borderRadius: AppTheme.borderRadius,
    padding: 17,
    display: 'flex',
    justifyContent: 'center'
  },
  quickListTitleContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 0,
    fontWeight: 500
  },
  contactBannerContainer: {
    marginTop: 40
  },
  contactBannerImgContainer: {
    width: 198,
    height: 126,
    marginTop: -30,
  },
  contactBannerOuterContainer: {
    minHeight: 115
  },
  contactBannerTextContainer: {
    padding: '24px 10px 24px 30px'
  },
  contactBannerTitle: {
    fontSize: 16,
    color: CommonTheme.textColor,
    margin: 0
  },
  contactUsBannerTitleContainer: {
    display: 'flex',
    marginTop: 8,
  },
  contactUsBannerTitle: {
    fontSize: 34,
    color: AppTheme.primaryColor,
    margin: 0,
    fontWeight: 600
  },
  imageHandClickContainer: {
    width: 25,
    height: 25,
    marginLeft: -3,
    marginTop: -3
  },
  lineStatItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '49%',
  },
  analyticsDashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: AppTheme.viewPort,
    overflow: 'hidden',
    marginBottom: 20,
  },
  analyticsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 10,
  },
  analyticsDataContainer: {
    marginTop: 51,
  },
  dateRangeContainer: {
    position: 'fixed',
    top: 140,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    maxWidth: AppTheme.viewPort,
    width: '100%',
    backgroundColor: AppTheme.secondaryBg,
    paddingBottom: 20,
    '@media (max-width: 767px)': {
      width: '90%',
    },
  },
  tableStatItem: {
    flex: 1,
    height: '100%',
    '@media (max-width: 767px)': {
      width: '100%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
  },
  tableStatGridItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      marginBottom: 0,
    },
    width: '49%',
    marginBottom: '40px'
  },
  pieStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '49%',
  },
  formControlMainFilters: {
    width: 200,
    '@media (max-width: 440px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  label: {
    fontSize: 14,
    color: 'grey',
    margin: 0,
    // marginBottom: 8,
    display: 'flex',
  },
  formControlFilters: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden'
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16
  },
  autoCompleteInputRoot: {
    paddingRight: 56
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  textInput: {
    padding: '4px 0px'
  },
})