import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppConfig } from '../../AppConfig';
import { CustomAssetsStyles } from '../../styles/CustomStyles';
import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { DownloadDialog } from '../../components/DownloadDialog';
import { AppUiHelper } from '../../helpers/AppUiHelper';


export class Forms extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.pageTitle = options.pageTitle || Helper.getString('forms')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('forms') },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['forms'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 100 } },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId }) },
      { ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = 'forms'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Info: (current) => {
        return UiHelper.itemTitleId(current)
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.formDefinition = results[0].fieldDef
    this.staffUsers = results[1].items
    this.crmContactAttrs = results[2]
    Helper.loadAttrOptions(this.formDefinition, this.crmContactAttrs, 'tags', 'defaultTag')
    UiHelper.populateOptions(this.formDefinition, {items: this.staffUsers}, 'defaultAssignee')

    this.setState({
      items: results[0].items
    })
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['forms'], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['forms', current.id], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['forms', current.id], {}),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(
      <Link to={this.baseRoute() + '/forms/' + current.id + '/fields'} key={current.id + '-fields'}>
        <Tooltip title='Fields' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>format_list_numbered</i>
          </span>
        </Tooltip>
      </Link>
    )
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(
      <Tooltip title='Duplicate' placement='top-start' key={current.id + 'Duplicate'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => { this.openModalForActions(current)}}>copy_all</i>
        </span>
      </Tooltip>
    )
    actions.push(
      <Tooltip title='Download Form Submissions' placement='top-start' key={current.id + 'DL'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForExportSubmits(current)}>download_for_offline</i>
        </span>
      </Tooltip>
    )
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }

  renderAboveTable() {
    return (
      <DownloadDialog
        downloadDialog={this.state.downloadDialog}
        data={this.state.exportResponse}
        onClosed={() => this.setState({ downloadDialog: false })}
        inProcess={this.state.inProcess}
        errMsg={this.state.errMsg}/>
    )
  }

  openModalForExportSubmits = (current) => {
    this.setState({
      downloadDialog: true,
      inProcess: true
    })

    ApiHelper.call({
      method: 'POST',
      jsonBody: {orgId: this.orgId, dataSourceId: current.id},
      endPoint: ApiHelper.makeUrlPath(['miscs', 'export-submits'], { orgId: this.orgId }),
    }, (err, result) => {
      if (err) {
        return this.setState({
          inProcess: false,
          errMsg: Helper.getErrorMsg(err)
        })
      }
      console.log('export-submits::result:', result)
      this.setState({
        exportResponse: result,
        inProcess: false,
        errMsg: undefined
      })
    })
  }

  openModalForActions(current) {
    console.log('edit:', current);
    this.formDefinition = AppUiHelper.getFieldDefForDuplication()
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['miscs', 'form-duplication'], {orgId: this.orgId}),
      apiData: {
        formId: current.id,
      },
      modalOpen: true
    });
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomAssetsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})