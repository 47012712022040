/* eslint-disable camelcase */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { Helper } from '../../../common/helpers/Helper';
import { SubEntityCard } from '../../../common/components/SubEntity/SubEntityCard';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { Controller } from '../../../common/Controller';

const CREATABLE_TYPES = ['NOTE', 'EMAIL']
const EDITABLE_TYPES = ['NOTE']
const VIEWABLE_TYPES = ['FORM_SUBMISSION', 'EMAIL']

export class CrmContact extends BaseEntityPageSlixta {

  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageKey = 'crm-contact'
    this.pageTitle = Helper.getString('crm-contact')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.crmUserId = this.props.match.params.userId
    this.staffUserId = Controller.get().getUserId()
    this.orgIdUserId = `${this.orgId}-${this.crmUserId}`
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.inboxesGroupId = 'settingscrminboxes' + this.orgId
    this.apiData = {
      orgId: this.orgId,
      userId: this.crmUserId,
    }
    this.staffUsers = []
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-contacts'), to: `${this.baseRoute()}/crm-contacts` },
      { title: this.crmUserId },
    ]
    this.toFetch = [
      { key: 'crminteractions', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crminteractions'], queryParams: { orgIdUserId: this.orgIdUserId, pb: 'fieldDef', limit: 100 } },
      { key: 'usersORG', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId }) },
      { key: 'crmuser', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts', this.orgIdUserId], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { key: 'crmcontactattrs', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
      { key: 'crminboxes', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, includeShared: 'true' } },
      { key: 'crmemailtemplates', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmemailtemplates'], queryParams: { orgId: this.orgId, pb: 'fieldDef', includeShared: 'true', limit: 100 }},
      { key: 'crmusersettings', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `settingscrmusersettings${this.orgId}-${this.staffUserId}-all`], queryParams: { groupId: `settingscrmusersettings${this.orgId}-${this.staffUserId}` } },
      { key: 'crmsequences', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['campaigns'], queryParams: { orgId: this.orgId, iTypeState: 'DRIP-LIVE' } },
    ]
    this.cellWidths = {
      Index: Styles.cellWidthSmall,
      Date: Styles.cellWidthNormal,
      Info: Styles.cellWidthNormal,
      Interaction: Styles.cellWidthWide,
    }
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Date: (current) => { return Helper.formatTimestamp(current.createdTs) },
      Info: (current) => {
        if (current.iType === 'CONTACT_CREATED') return current.contactOrigin
        const creator = current.iType === 'FORM_SUBMISSION' || current.creatorId === this.crmUserId ? this.crmUser : this.staffUsers.find(u => u.id === current.creatorId || u.userId === current.creatorId)
        const emailClickTrail = UiHelper.renderEmailClickTrail(current.emailClickTrail || [])
        return <div>
          {creator && <span className={css(Styles.line2)}>Creator: {Helper.formatName(creator)}</span>}
          <br/>
          {current.emailSource && <span className={css(Styles.line2)}>Source: {current.emailSource === 'DRIP_CAMPAIGN' ? <a href={this.baseRoute() + '/campaigns/sequences/' + current.campaignId + '/enrolments'} target='_blank'><span>{current.emailSource}</span></a> : current.emailSource}</span>}
          <br/>
          {current.iType === 'EMAIL' && (current.emailSource === 'CONSOLE' || current.emailSource === 'DRIP_CAMPAIGN') && <span title={emailClickTrail} className={css(Styles.line2)}>{(current.emailBounceCount || 0) > 0 ? 'Email has bounced' : `Opens: ${current.emailOpenCount || 0} | Clicks: ${current.emailClickCount || 0} | Replies: ${current.emailReplyCount || 0}`}</span>}
        </div>
      },
      Interaction: (current) => {
        let summary = ''
        switch (current.iType) {
        case 'NOTE': summary = Helper.renderHtmlText(current.noteRichText); break;
        case 'FORM_SUBMISSION': summary = current.formSubmission.replaceAll('\n','<br/>'); break;
        case 'EMAIL':
        {
          const from = current.emailFrom ? `<p>From: ${Helper.escapeHtml(current.emailFrom)}</p>` : ''
          const to = current.emailTo && current.emailTo.length > 0 ? `<p>To: ${(current.emailTo || []).map(e => Helper.escapeHtml(e)).join(', ')}</p>` : ''
          const cc = current.emailCC && current.emailCC.length > 0 ? `<p>Cc: ${(current.emailCC || []).map(e => Helper.escapeHtml(e)).join(', ')}</p>` : ''
          const bcc = current.emailBCC && current.emailBCC.length > 0 ? `<p>Bcc: ${(current.emailBCC || []).map(e => Helper.escapeHtml(e)).join(', ')}</p>` : ''
          const subject = `<p>Sub: ${Helper.escapeHtml(current.emailSubject)}</p>`
          const body = current.emailBodyHtmlText ? Helper.addHtmlTextUrlQueryParams(current.emailBodyHtmlText, {__slxignore: true}) : Helper.addRichTextUrlQueryParams(Helper.renderHtmlText(current.emailBodyRichText), {__slxignore: true})
          summary = from + to + cc + bcc + subject + body;
          break;
        }
        case 'CONTACT_CREATED': summary = current.source; break;
        }
        return (
          <div>
            <span className={css(Styles.line1)}>{current.iType}</span>
            <br/>
            <span className={css(Styles.line2, Styles.summaryContainer)} dangerouslySetInnerHTML={{ __html: summary }}></span>
          </div>
        )
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.headerActions = [
      {
        label: 'Enroll',
        icon: 'dynamic_feed',
        onClick: () => UiHelper.openModalForSequences.call(this, this.crmUser, this.crmSequences, this.crmInboxes),
      },
      {
        label: 'Interaction',
        tooltip: 'Add Interaction',
        icon: 'add',
        type: 'icon-label',
        onClick: () => this.openModalForAddStep1(),
      },
      {
        label: 'Refresh',
        tooltip: 'Refresh',
        icon: 'refresh',
        type: 'icon-label',
        onClick: () => this.fetchItems(),
      }
    ]
    this.uploadLocation = `${this.orgId}/__FILENAME__`
    this.uploadMs = AppConfig.CDP_MS.ENDPOINT
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    if (!fetchNext) {
      this.crmInteractions = this.getResult(results, 'crminteractions').items
      this.fieldDef = this.formDefinition = this.getResult(results, 'crminteractions').fieldDef
      this.staffUsers = this.getResult(results, 'usersORG').items
      this.crmUser = this.getResult(results, 'crmuser')
      this.crmUserFieldDef = this.getResult(results, 'crmuser').fieldDef
      this.crmContactAttrs = this.getResult(results, 'crmcontactattrs')
      this.crmInboxes = this.getResult(results, 'crminboxes').items
      this.crmEmailTemplates = this.getResult(results, 'crmemailtemplates').items
      this.crmEmailTemplateFieldDef = this.getResult(results, 'crmemailtemplates').fieldDef
      this.crmUserSettings = this.getResult(results, 'crmusersettings')
      this.crmSequences = this.getResult(results, 'crmsequences').items.sort((a, b) => b.createdTs - a.createdTs)
      this.crmUserSettingsEmailSignatureRichText = (this.crmUserSettings && this.crmUserSettings.emailSignatureRichText) || ''
      this.currentStaffUser = this.staffUsers.find(u => u.userId === this.staffUserId) || {}
      Helper.loadAttrOptions(this.crmUserFieldDef, this.crmContactAttrs, 'tags')
      UiHelper.populateOptions(this.crmUserFieldDef, {items: this.staffUsers}, 'assignee')
      UiHelper.populateOptions(this.fieldDef, {items: this.staffUsers}, 'creatorId')
      this.fieldDef.steps[0].fields.find(f => f.key === 'creatorId').options.push({key: this.orgIdUserId, label: Helper.formatName(this.crmUser)})
      UiHelper.populateOptions(this.fieldDef, {items: this.staffUsers}, 'noteMentions')
      const emailFromField = this.fieldDef.steps[0].fields.find(f => f.key === 'emailFrom')
      const emailFromInboxField = this.fieldDef.steps[0].fields.find(f => f.key === 'emailFromInbox')
      emailFromInboxField.v_required = {...emailFromInboxField.v_required, value: true}
      if (this.crmInboxes.length > 0) {
        this.crmInboxes.forEach(inbox => {
          emailFromField.options.push({key: inbox.email, label: inbox.email})
          emailFromInboxField.options.push({key: inbox.id, label: inbox.email})
        })
        emailFromField.defaultValue = this.crmInboxes[0].email
        emailFromInboxField.defaultValue = this.crmInboxes[0].id
      }
      const addresseeFieldNames = ['emailTo', 'emailCC', 'emailBCC']
      const addresseeOptions = this.staffUsers.map(staffUser => {
        const fullEmail = Helper.formatUserEmail(staffUser)
        return {key: fullEmail, label: fullEmail}
      })
      addresseeFieldNames.forEach(fieldName => {
        const field = this.fieldDef.steps[0].fields.find(f => f.key === fieldName)
        if (field) field.options = [...addresseeOptions]
        if (this.crmUser.email) {
          const fullEmail = Helper.formatUserEmail(this.crmUser)
          field.options.unshift({key: fullEmail, label: fullEmail})
          if (fieldName === 'emailTo') {
            field.defaultValue = [fullEmail]
          }
        }
      })

      this.breadCrumbs = [
        { title: Helper.getString('home'), to: this.baseRoute() },
        { title: Helper.getString('crm-contacts'), to: `${this.baseRoute()}/crm-contacts` },
        { title: Helper.formatName(this.crmUser) || '?' },
      ]
    } else {
      this.state.items.pop() // remove manually added CONTACT_CREATED interaction
    }
    this.transform(results[0], this.crmUser);
    this.handleNextpage(results[0])
    const mergedItems = fetchNext ? this.state.items.concat(results[0].items) : results[0].items
    const emailMessageIdOptions = []
    for (const i of mergedItems) {
      if (i.iType === 'EMAIL') {
        emailMessageIdOptions.push({
          key: i.emailMessageId,
          text: i.emailSubject,
        })
      }
    }
    const emailInReplyToMessageIdField = this.fieldDef.steps[0].fields.find(f => f.key === 'emailInReplyToMessageId')
    if (emailInReplyToMessageIdField) emailInReplyToMessageIdField.options = emailMessageIdOptions
    this.setState({
      items: mergedItems,
      staffUsers: this.staffUsers,
      currentStaffUser: this.currentStaffUser,
      crmUser: this.crmUser,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  renderAboveTable() {
    const crmUserCp = JSON.parse(JSON.stringify(this.state.crmUser))
    const originalEmail = this.state.crmUser.email
    return (
      <div className={css(Styles.containerNarrow)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <SubEntityCard
              renderFieldsRowwise={true}
              title={'Summary'} formDefinition={this.crmUserFieldDef}
              item={crmUserCp || {}}
              openModalForEdit={(current) => {
                current.email = originalEmail
                return this.openModalForEdit(current, this.crmUserFieldDef)}
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  transform(result, crmUser) {
    if (!crmUser || !result || !result.items) return

    const crmUserCreator = this.staffUsers && this.staffUsers.find(u => u.id === crmUser.creatorId)
    const contactOrigin = `${(crmUser.createdOnFormSubmission ? 'FORM' : crmUser.createdByImport ? 'IMPORT' : 'CONSOLE')} ${crmUserCreator ? ` (${Helper.formatName(crmUserCreator)})` : ''}`

    const displayLabels = []
    if (crmUser.cognitoVerified) displayLabels.push('OTP VERIFIED')
    if (crmUser.emailAdminVerified) displayLabels.push('EMAIL VERIFIED')
    if (crmUser.phoneAdminVerified) displayLabels.push('PHONE VERIFIED')
    result.items.push({
      id: '0',
      iType: 'CONTACT_CREATED',
      createdTs: crmUser.createdTs,
      contactOrigin,
      source: crmUser.source,
      displayLabels,
    })
  }

  openModalForAddStep1(inReplyToCrmInteraction, isReplyAll = false) {
    this.apiData = {
      orgId: this.orgId,
      userId: this.crmUserId,
      isAddStep1: true,
      inReplyToCrmInteraction,
      isReplyAll,
    }
    this.formDefinition = JSON.parse(JSON.stringify(this.fieldDef))
    this.formDefinition.formType = 'add'
    this.formDefinition.ctaButtonText = 'Next'
    this.formDefinition.disableAlwaysFormFields = this.formDefinition.disableAlwaysFormFields || []
    this.formDefinition.steps[0].fields = this.formDefinition.steps[0].fields.filter(f => ['iType', 'creatorId', 'emailFromInbox', 'emailInReplyToMessageId'].includes(f.key))
    const iTypeField = this.formDefinition.steps[0].fields.find(f => f.key === 'iType')
    iTypeField.options = iTypeField.options.filter(o => CREATABLE_TYPES.includes(o.key) )
    const creatorIdField = this.formDefinition.steps[0].fields.find(f => f.key === 'creatorId')
    if (creatorIdField) { creatorIdField.defaultValue = `${this.orgId}-${this.staffUserId}` }
    if (inReplyToCrmInteraction) {
      iTypeField.defaultValue = 'EMAIL'
      this.formDefinition.disableAlwaysFormFields.push('iType')
      const emailInReplyToMessageIdField = this.formDefinition.steps[0].fields.find(f => f.key === 'emailInReplyToMessageId')
      if (emailInReplyToMessageIdField) { emailInReplyToMessageIdField.defaultValue = inReplyToCrmInteraction.emailMessageId }
    } else {
      this.formDefinition.steps[0].fields = this.formDefinition.steps[0].fields.filter(f => f.key !== 'emailInReplyToMessageId')
    }
    this.formDefinition.disableAlwaysFormFields = ['creatorId', 'emailInReplyToMessageId']
    this.formDefinition.steps[0].fields.push({
      key: 'emailTemplate',
      type: 'radio',
      label: 'Template',
      options: [{key: 'NONE', label: 'NONE'}].concat(this.crmEmailTemplates.map(t => ({key: t.id, label: t.title}))),
      defaultValue: 'NONE',
      // eslint-disable-next-line camelcase
      v_required: { value: true, err: 'Provide email template' },
      placement: 'left',
      condition: { key: 'iType', value: 'EMAIL', values: ['EMAIL'] },
    })
    this.formDefinition.steps[0].fields.push({
      key: 'emailSignature',
      type: 'radio',
      label: 'Include Signature',
      options: [{key: 'YES', label: 'YES'}, {key: 'NO', label: 'NO'}],
      defaultValue: 'YES',
      // eslint-disable-next-line camelcase
      v_required: { value: true, err: 'Provide signature' },
      placement: 'right',
      condition: { key: 'iType', value: 'EMAIL', values: ['EMAIL'] },
    })
    this.setState({
      modalOpen: true
    });
  }

  openModalForAdd(fieldDef, inReplyToCrmInteraction, isReplyAll, apiData = {}) {
    this.apiData = {
      orgId: this.orgId,
      userId: this.crmUserId,
      ...apiData,
    }
    const fieldDefCp = JSON.parse(JSON.stringify(fieldDef))
    fieldDefCp.disableAlwaysFormFields = ['iType', 'creatorId', 'formSubmission', 'emailFromInbox', 'emailInReplyToMessageId']
    fieldDefCp.steps[0].fields = fieldDefCp.steps[0].fields.filter(f => f.key !== 'emailFrom')
    if (inReplyToCrmInteraction) {
      const emailFromInboxField = fieldDef.steps[0].fields.find(f => f.key === 'emailFromInbox') || {}
      const emailFromInbox = this.crmInboxes.find(inbox => inbox.id === emailFromInboxField.defaultValue) || {}
      const inReplyToEmailTo = (inReplyToCrmInteraction.emailTo || [])
      const inReplyToEmailCc = (inReplyToCrmInteraction.emailCC || []).filter(e => !Helper.isEmailEqual(e, this.currentStaffUser.email))
      const inReplyToEmailBcc = (inReplyToCrmInteraction.emailBCC || []).filter(e => !Helper.isEmailEqual(e, this.currentStaffUser.email))
      const inReplyToEmailToFilterCc = inReplyToEmailTo.filter(toE => !inReplyToEmailCc.includes(toE) && !Helper.isEmailEqual(toE, this.currentStaffUser.email))
      const inReplyToEmailFrom = [inReplyToCrmInteraction.emailFrom]
      const inReplyToSentByMe = this.crmInboxes.some(i => Helper.isEmailEqual(inReplyToCrmInteraction.emailFrom || '', i.email))
      fieldDefCp.steps[0].fields.forEach(f => {
        if (f.key in inReplyToCrmInteraction && ['emailTo', 'emailCC', 'emailBCC'].includes(f.key)) {
          f.options = (f.options || []).concat(inReplyToEmailTo.concat(inReplyToEmailCc).concat(inReplyToEmailBcc).filter(e => f.options.every(o => o.key !== e)).map(e => ({key: e, label: e})))
          if (isReplyAll) {
            if (f.key === 'emailTo') {
              f.defaultValue = inReplyToSentByMe ? inReplyToEmailTo : inReplyToEmailFrom
            } else if (f.key === 'emailCC') {
              f.defaultValue = inReplyToSentByMe ? inReplyToEmailCc : inReplyToEmailCc.concat(inReplyToEmailToFilterCc)
              if (Array.isArray(f.defaultValue) && f.defaultValue.length > 1) f.defaultValue = f.defaultValue.filter(e => !Helper.isEmailEqual(e, emailFromInbox.email))
            } else if (f.key === 'emailBCC') {
              f.defaultValue = inReplyToSentByMe ? inReplyToEmailBcc : []
              if (Array.isArray(f.defaultValue) && f.defaultValue.length > 1) f.defaultValue = f.defaultValue.filter(e => !Helper.isEmailEqual(e, emailFromInbox.email))
            }
          } else {
            if (f.key === 'emailTo') {
              f.defaultValue = inReplyToSentByMe ? inReplyToEmailTo : inReplyToEmailFrom
              if (Array.isArray(f.defaultValue) && f.defaultValue.length > 1) f.defaultValue = f.defaultValue.filter(e => !Helper.isEmailEqual(e, emailFromInbox.email))
            } else if (!['emailCC', 'emailBCC'].includes(f.key)) {
              f.defaultValue = inReplyToCrmInteraction[f.key]
            }
          }
        }
      })
      const emailSubjectField = fieldDefCp.steps[0].fields.find(f => f.key === 'emailSubject')
      if (emailSubjectField) { emailSubjectField.defaultValue = inReplyToCrmInteraction.emailSubject.startsWith('Re: ') ? inReplyToCrmInteraction.emailSubject : `Re: ${inReplyToCrmInteraction.emailSubject}` }
    } else {
      fieldDefCp.steps[0].fields = fieldDefCp.steps[0].fields.filter(f => f.key !== 'emailInReplyToMessageId')
    }
    super.openModalForAdd(fieldDefCp, false)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crminteractions'], queryParams: {} }),
    });
  }

  openModalForEdit(current, formDefinition) {
    this.apiData = {
      orgId: this.orgId,
      userId: this.crmUserId,
    }
    if (formDefinition) {
      this.formDefinition = formDefinition
      this.formDefinition.formType = 'edit'
      Helper.populateFormDefinitionValues(this.formDefinition, current)
      this.setState({
        apiMethod: 'POST',
        apiEndPoint: ApiHelper.makeUrlPath2({
          ms: AppConfig.CDP_MS.ENDPOINT,
          paths: ['miscs', 'update-crm-contact'],
          queryParams: { id: current.id, orgId: this.orgId }
        }),
      });
      this.setState({ modalOpen: true })
    } else {
      const fieldDefCp = JSON.parse(JSON.stringify(this.fieldDef))
      fieldDefCp.steps[0].fields = fieldDefCp.steps[0].fields.filter(f => f.key !== 'emailFromInbox')
      const emailFromField = fieldDefCp.steps[0].fields.find(f => f.key === 'emailFrom')
      if (emailFromField && emailFromField.options.every(o => o.key !== current.emailFrom)) {
        emailFromField.options.push({key: current.emailFrom, text: current.emailFrom, label: current.emailFrom})
      }
      const emailAttachmentField = fieldDefCp.steps[0].fields.find(f => f.key === 'emailAttachment')
      if (emailAttachmentField) {
        emailAttachmentField.forceAllowFileOpen = true
      }
      super.openModalForEdit(current, {
        steps: fieldDefCp.steps,
        disableAlwaysFormFields: ['iType', 'creatorId', 'formSubmission', 'emailFrom', 'emailTo', 'emailCC', 'emailBCC', 'emailSubject', 'emailBodyRichText', 'emailAttachment', 'emailAttachments', 'emailInReplyToMessageId'],
      });
    }
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crminteractions', current.id], queryParams: {} }),
    });
  }

  openModalForDelete(current, fieldDef) {
    this.apiData = {
      orgId: this.orgId,
      userId: this.crmUserId,
    }
    super.openModalForDelete(current, fieldDef)
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['crminteractions', current.id], queryParams: {} }),
    });
  }

  onActions = (current) => {
    const actions = []
    if (EDITABLE_TYPES.includes(current.iType)) {
      actions.push(UiHelper.buttonEdit(this, current))
      actions.push(UiHelper.buttonDelete(this, current))
    } else if (VIEWABLE_TYPES.includes(current.iType)) {
      actions.push(<Tooltip title={'View'} placement='top-start' key={current.id + 'viewFormSub'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForEdit(current)}>visibility</i>
        </span>
      </Tooltip>)
      if (current.iType === 'EMAIL') {
        actions.push(<Tooltip title={'Reply'} placement='top-start' key={current.id + 'replyEmail'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.openModalForAddStep1(current)}>reply</i>
          </span>
        </Tooltip>)
        const hasMultipleEmailTo = Array.isArray(current.emailTo) && current.emailTo.length > 1
        const hasEmailCc = Array.isArray(current.emailCC) && current.emailCC.length > 0
        const hasEmailBcc = Array.isArray(current.emailBCC) && current.emailBCC.length > 0
        if (hasMultipleEmailTo || hasEmailCc || hasEmailBcc) {
          actions.push(<Tooltip title={'Reply All'} placement='top-start' key={current.id + 'replyAllEmail'}>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined' onClick={() => this.openModalForAddStep1(current, true)}>reply_all</i>
            </span>
          </Tooltip>)
        }
      }
    }
    return actions
  }

  async onFormFieldOptionAdd(options) {
    const { formField, value, formId } = options
    if (this.fieldDef.formId === formId) {
      formField.options.push({
        key: value,
        label: value
      })
      return formField.options || []
    }
    return []
  }

  customSubmitForAddStep1(options) {
    const fieldDef = JSON.parse(JSON.stringify(this.fieldDef))
    const { formData } = options

    const inReplyToCrmInteraction = formData.inReplyToCrmInteraction
    const isReplyAll = formData.isReplyAll

    delete formData.isAddStep1
    delete formData.inReplyToCrmInteraction
    delete formData.isReplyAll

    const apiData = {}

    fieldDef.steps[0].fields.find(f => f.key === 'iType').defaultValue = formData.iType
    fieldDef.steps[0].fields.find(f => f.key === 'creatorId').defaultValue = formData.creatorId
    fieldDef.steps[0].fields.find(f => f.key === 'emailInReplyToMessageId').defaultValue = formData.emailInReplyToMessageId || ''
    fieldDef.steps[0].fields.find(f => f.key === 'emailFromInbox').defaultValue = formData.emailFromInbox
    if (formData.iType === 'EMAIL') {
      apiData.emailSource = 'CONSOLE'
      fieldDef.ctaButtonText = 'Send'
      if (formData.emailTemplate !== 'NONE') {
        const emailTemplate = this.crmEmailTemplates.find(t => t.id === formData.emailTemplate)
        fieldDef.steps[0].fields.find(f => f.key === 'emailSubject').defaultValue = emailTemplate.subject
        fieldDef.steps[0].fields.find(f => f.key === 'emailBodyRichText').defaultValue = emailTemplate.bodyRichText
        if (Array.isArray(emailTemplate.attachment) ? emailTemplate.attachment.length > 0 : emailTemplate.attachment) {
          const emailAttachmentField = fieldDef.steps[0].fields.find(f => f.key === 'emailAttachment')
          emailAttachmentField.defaultValue = emailTemplate.attachment
          emailAttachmentField.link = emailTemplate.attachmentLink
        }
      }
      const emailFromInboxField = fieldDef.steps[0].fields.find(f => f.key === 'emailFromInbox')
      const emailFromInbox = this.crmInboxes.find(inbox => inbox.id === emailFromInboxField.defaultValue) || {}
      if (formData.emailSignature === 'YES') {
        const emailBodyField = fieldDef.steps[0].fields.find(f => f.key === 'emailBodyRichText')
        const signatureRichText = Helper.renderPlainText(emailFromInbox.signatureRichText) ? emailFromInbox.signatureRichText : (this.crmUserSettingsEmailSignatureRichText || '')
        emailBodyField.defaultValue = emailBodyField.defaultValue ? Helper.mergeRichText(emailBodyField.defaultValue, signatureRichText) : signatureRichText
      }
      const templateValues = {
        'user.name': Helper.formatName(this.crmUser),
        'user.givenname': this.crmUser.givenname || '',
        'user.firstname': this.crmUser.givenname || '',
        'user.middlename': this.crmUser.middlename || '',
        'user.familyname': this.crmUser.familyname || '',
        'user.lastname': this.crmUser.familyname || '',
        'user.email': this.crmUser.email || '',
        'user.phone': this.crmUser.phone || '',
        'user.company': this.crmUser.company || '',
        'contact.name': Helper.formatName(this.crmUser),
        'contact.givenname': this.crmUser.givenname || '',
        'contact.firstname': this.crmUser.givenname || '',
        'contact.middlename': this.crmUser.middlename || '',
        'contact.familyname': this.crmUser.familyname || '',
        'contact.lastname': this.crmUser.familyname || '',
        'contact.email': this.crmUser.email || '',
        'contact.phone': this.crmUser.phone || '',
        'contact.company': this.crmUser.company || '',
        'sender.name': emailFromInbox.senderName || '',
        'sender.email': emailFromInbox.emailRaw || '',
      }
      const emailSubject = fieldDef.steps[0].fields.find(f => f.key === 'emailSubject')
      const emailBodyRichText = fieldDef.steps[0].fields.find(f => f.key === 'emailBodyRichText')
      emailSubject.defaultValue = Helper.replaceText(emailSubject.defaultValue || '', templateValues)
      emailBodyRichText.defaultValue = Helper.replaceRichText(emailBodyRichText.defaultValue, templateValues)
    }
    this.setState({ fetchState: ApiHelper.State.READY, modalOpen: false }, () => {
      this.openModalForAdd(fieldDef, inReplyToCrmInteraction, isReplyAll, apiData)
    })
  }

  async customSubmit(options) {
    const { formType, formData, caller } = options
    if (formData.isAddStep1) { return this.customSubmitForAddStep1(options) }
    if ('seqEnrolmentNextStepIndex' in formData) { return UiHelper.customSubmitForSequences.call(this, options) }

    return caller.submit.call(caller, formData, true);
  }
}

const Styles = StyleSheet.create({
  line1: {
    fontSize: 16,
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
  summaryContainer: {
    wordBreak: 'break-word'
  },
  cellWidthSmall: {
    width: '10%',
  },
  cellWidthNormal: {
    width: '20%',
  },
  cellWidthWide: {
    width: '50%',
  },
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
})