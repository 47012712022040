import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { dataItem } from '../data/data';
import { AppTheme } from '../styles/AppTheme';

export class Banner extends Component {
  constructor(props) {
    super(props)
    this.data = dataItem.homePage
  }

  render() {
    return (
      <div className={css(Styles.outerContainer)} >
        <div className={css(Styles.imgOuterContainer)}>
          <div className={css(Styles.imageContainer)}>
            <img className={css(Styles.image)}
              src={this.data.imgHero} />
          </div>
        </div>
        <div className={css(Styles.titleOuterContainer)}>
          <div className={css(Styles.titleDescrContainer)}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  titleOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.9
  },
  titleDescrContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
    flex: 1,
    maxWidth: 390,
    '@media (max-width: 767px)': {
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  imgOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1.1,
    backgroundColor: '#12214E',
    height: '100%',
    '@media (max-width: 767px)': {
      height: 'auto',
      paddingTop: AppTheme.toolbarHeight,
      paddingBottom: 50
    }
  },
  imageContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    maxHeight: 400,
    justifyContent: 'center',
    padding: '0px 20px',
    '@media (max-width: 767px)': {
      marginTop: 20,
      maxHeight: 250,
    }
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
})