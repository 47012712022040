import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppStyles } from '../../styles/AppTheme';
import { CustomCbDefsReadStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { CbSelection } from '../../common/components/CbSelection';
import { Headerbar } from '../../common/components/Headerbar';
import { AppUiHelper } from '../../helpers/AppUiHelper';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';

export class EmailPageCbs extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('emailpagecbs')
    this.pageId = this.props.match.params.id
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.toFetch = [
      { method: 'GET', paths: ['emailpages', this.pageId], queryParams: { pb: 'fieldDefPageBlock' } },
      { method: 'GET', paths: ['emailcbdefs'], queryParams: { orgId: 'PRODUCT', pb: 'fieldDef' } },
      // { method: 'GET', paths: ['items', 'emailcborg' + this.orgId + '-all'], queryParams: { groupId: 'emailcborg' + this.orgId, pb: 'fieldDef' } }
    ]
    this.tableHeadersMap = {
      Order: (current) => { return current.order },
      Section: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title || '(no nickname)'}</span>
            <br/>
            <span className={css(Styles.spanValue, Styles.line2)}>{current._widgetName}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.entityAttrName = 'blocks'
  }

  onFetchSuccess(results) {
    this.page = results[0]
    this.fieldDef = this.formDefinition = this.page.fieldDefPageBlock
    this.cbSource = this.fieldDef.steps[0].fields.find((o) => o.key === 'cbSource')
    if(this.cbSource && this.cbSource.options) {
      this.cbSource.options = this.cbSource.options.filter(o => o.key === 'GLOBAL')
    }
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('emailpages'), to: this.baseRoute() + '/emailpages' },
      { title: this.page.title }
    ]
    this.cbGlobal = results[1]
    console.log('this.cbGlobal:', this.cbGlobal)
    this.cbGlobalById = Helper.itemsToMap(this.cbGlobal.items)
    // this.cbOrg = results[2]
    // console.log('this.cbOrg:', this.cbOrg)
    // this.cbOrgById = Helper.itemsToMap(this.cbOrg.items)
    // this.cbSite = {
    //   items: Helper.dictToArray(results[3].items)
    // }
    // console.log('this.cbSite:', this.cbSite)
    // this.cbSiteById = Helper.itemsToMap(this.cbSite.items)

    UiHelper.populateOptions(this.formDefinition, this.cbGlobal, 'cbGlobalId')
    // UiHelper.populateOptions(this.formDefinition, this.cbOrg, 'cbTenantId')
    // UiHelper.populateOptions(this.formDefinition, this.cbSite, 'cbSiteBlockId')
    const widgets = Helper.sortDictArray(Helper.dictToArray(this.page[this.entityAttrName]))
    widgets.forEach(i => {
      if(i.cbGlobalId && this.cbGlobalById[i.cbGlobalId]) {
        i._widgetName = this.cbGlobalById[i.cbGlobalId].title
        i._singleton = this.cbGlobalById[i.cbGlobalId].singleton
        i._cbScopeId = 'p' + this.orgId + '-' + i.id + '-' + i.cbGlobalId
      // } else if(i.cbTenantId && this.cbOrgById[i.cbTenantId]) {
      //   i._widgetName = this.cbOrgById[i.cbTenantId].title
      //   i._singleton = this.cbOrgById[i.cbTenantId].singleton
      //   i._cbScopeId = 'p' + this.orgId + '-' + i.id + '-' + i.cbTenantId
      // } else if(i.cbSiteBlockId && this.cbSiteById[i.cbSiteBlockId]) {
      //   i._widgetName = this.cbSiteById[i.cbSiteBlockId].title
      }
    })
    console.log('widgets:', widgets)
    console.log('widgets:names:', widgets.map(i => i._widgetName))
    this.setState({
      items: widgets
    })
  }

  onAdd() {
    this.formDefinition.disableAlwaysFormFields = ['cbSource', 'cbTenantId', 'cbGlobalId', 'cbSiteBlockId']

    if (this.state.selectedBlockSource === 'GLOBAL') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').value = this.state.selectedBlockType
    // } else if (this.state.selectedBlockSource === 'TENANT') {
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').value = this.state.selectedBlockType
    // } else if (this.state.selectedBlockSource === 'SITE') {
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').value = this.state.selectedBlockType
    }

    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').value = this.state.selectedBlockSource
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').defaultValue = this.state.selectedBlockSource

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.pageId], {}),
    });
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
    // this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    // this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.pageId], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.pageId], {}),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current, 'Edit section'))
    actions.push(UiHelper.buttonDelete(this, current))
    if (current.cbSource === 'GLOBAL' || current.cbSource === 'TENANT') {
      if(current._singleton  === 'YES') {
        // Dont go to another page for convenience of only one item to be edited
        actions.push(
          <Tooltip title={Helper.getString('emailcbdats')} placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'
                onClick={() => AppUiHelper.handleEmailSingletonWidgetData(this, current)}>dataset</i>
            </span>
          </Tooltip>
        )
      } else {
        actions.push(
          <Link to={this.baseRoute() + '/emailcbdats/' + current._cbScopeId + '?pageId=' + this.pageId} key={current.id + '-emailcbdats'}>
            <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
              <span className={css(AppStyles.entityTableLinkIcon)}>
                <i className='material-icons-outlined'>dataset</i>
              </span>
            </Tooltip>
          </Link>
        )
      }
    }
    return actions
  }


  renderHeaderbar() {
    const title = this.pageTitle ? this.pageTitle : null
    const pageKey = this.pageKey ? this.pageKey : null
    const actions = [{
      label: 'Add new',
      onClick: () => this.setState({ blockSelectionModal: true})
    }]
    actions.push({
      tooltip: Helper.getString('preview'),
      icon: 'desktop_windows',
      type: 'icon',
      onClick: () => window.open('/admin/orgs/' + this.orgId + '/preview?templateId=' + this.pageId, 'slixtaPreivew')
    })
    return (
      <Headerbar
        pageKey={pageKey}
        title={title} actions={actions}
        actionComponents={this.state.headerActionComponents}
        breadCrumbs={this.breadCrumbs}
      />
    )
  }

  doActionNext = (selectedBlockSource, selectedBlockType) => {
    this.setState({
      blockSelectionModal: false,
      selectedBlockSource: selectedBlockSource,
      selectedBlockType: selectedBlockType
    }, () => this.openModalForAdd())
  }

  renderBelowTable() {
    return this.state.blockSelectionModal ? this.renderBlockSelectionModal() : ''
  }

  renderBlockSelectionModal() {
    return (
      <CbSelection
        title={Helper.getString('emailblockSelection')}
        isOpen={this.state.blockSelectionModal}
        onClosed={() => this.setState({ blockSelectionModal: false})}
        cbOrg={this.cbOrg}
        selectedBlock={'GLOBAL'}
        cbGlobal={this.cbGlobal}
        cbSource={this.cbSource}
        cbSite={this.cbSite}
        doActionNext={this.doActionNext}
        page={this.page}
      />
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsReadStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})
