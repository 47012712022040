import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppStyles } from '../../styles/AppTheme';
import { AppConfig } from '../../AppConfig';
import { CustomCbDefsStyles } from '../../styles/CustomStyles';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';
import { Controller } from '../../common/Controller';

export class Pages extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('pages')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', paths: ['pages'], queryParams: { orgId: this.orgId,
        pb: 'fieldDef,fieldDefPageFulfilment,fieldDefSEO,fieldDefPageAdvanced' } },
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: this.orgId } },
      { method: 'GET', paths: ['miscs', 'cotypes'], queryParams: { orgId: this.orgId } },
      // { key: 'lists', method: 'GET', paths: ['lists'], queryParams: { orgId: this.orgId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Info: (current) => {
        const v = {...current}
        if(current.coTypeId) {
          v.badge = 'Multipage'
          v.slug = v.slug ? v.slug + '/<path>' : v.slug
        }
        return UiHelper.itemDetails(v)
      },
      Status: (current) => UiHelper.itemStatusContainer(current)
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    // this.conf = {
    //   previewPath: '/' // TODO: Was colliding with headerActions
    // }
    this.pageKey = 'pages'
    this.headerActions = [
      {
        label: 'Page',
        onClick: () => this.openModalForAdd()
      }
    ]
    if(Controller.get().roleHelper().isProductSU()) {
      this.headerActions.push({
        label: 'Multipage',
        // icon: 'view_stream',
        onClick: () => this.openModalForCoTypePageAdd()
      })
    }
  }

  onFetchSuccess(results) {
    this.fieldDefSEO = results[0].fieldDefSEO
    this.fieldDefPageAdvanced = results[0].fieldDefPageAdvanced
    this.fieldDefCoTypePage = {
      steps: [
        {fields: results[0].fieldDef.steps[0].fields.filter(i =>
          Helper.inArray(['title', 'slug', 'state', 'includeInSitemap', 'coTypeId'], i.key))}
      ]
    }
    this.fieldDef = {
      steps: [
        {fields: results[0].fieldDef.steps[0].fields.filter(i => !Helper.inArray(['coTypeId'], i.key)) }
      ]
    }
    this.fieldDefPageFulfilment = results[0].fieldDefPageFulfilment

    const cbdefData = results[1]
    cbdefData.items.forEach(i => i.id = i.slug)
    cbdefData.items.push({ id: 'benefits', title: 'Benefits' })
    cbdefData.items.push({ id: 'testimonials', title: 'Testimonials' })
    cbdefData.items.push({ id: 'faqs', title: 'FAQs' })
    UiHelper.populateOptions(this.fieldDef, cbdefData, 'entities')

    const coTypesData = results[2]
    UiHelper.populateOptions(this.fieldDefCoTypePage, coTypesData, 'coTypeId')
    this.setState({
      items: results[0].items
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['pages'], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['pages', current.id], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['pages', current.id], {}),
    });
  }

  openModalForFulfilmentEdit(current) {
    super.openModalForEdit(current, this.fieldDefPageFulfilment)
  }

  openModalForCoTypePageAdd() {
    super.openModalForAdd(this.fieldDefCoTypePage)
  }

  openModalForCoTypePageEdit(current) {
    super.openModalForEdit(current, this.fieldDefCoTypePage)
  }

  openModalForDuplicatePage(current) {
    console.log('edit:', current);
    this.formDefinition = pageActionsFieldDef
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['miscs', 'page-duplication'], {orgId: this.orgId}),
      apiData: {
        pageId: current.id,
      },
      modalOpen: true
    });
  }

  openModalForAdvanced(current) {
    console.log('openModalForAdvanced:', current);
    this.formDefinition = this.fieldDefPageAdvanced
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(
      <Tooltip title='Edit' placement='top-start' key={current.id + 'editTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => {
            current.coTypeId ? this.openModalForCoTypePageEdit(current) : this.openModalForEdit(current)
          }}>edit</i>
        </span>
      </Tooltip>
    )
    actions.push(UiHelper.buttonSEO(this, current))
    actions.push(
      <Tooltip title='Clone Page' placement='top-start' key={current.id + 'clone'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => { this.openModalForDuplicatePage(current)}}>copy_all</i>
        </span>
      </Tooltip>
    )
    actions.push(
      <Tooltip title='Advanced' placement='top-start' key={current.id + 'adv'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons-outlined' onClick={() => this.openModalForAdvanced(current)}>settings_suggest</i>
        </span>
      </Tooltip>
    )
    actions.push(UiHelper.buttonDelete(this, current))
    // actions.push(
    //   <Tooltip title='Fulfilment' placement='top-start' key={current.id + 'editTT'}>
    //     <span className={css(AppStyles.entityTableLinkIcon)}>
    //       <i className='material-icons-outlined' onClick={() => this.openModalForFulfilmentEdit(current)}>redeem</i>
    //     </span>
    //   </Tooltip>
    // )
    actions.push(
      <Link to={this.baseRoute() + '/pages/' + current.id + '/blocks'} key={current.id + '-cbdefs'}>
        <Tooltip title={Helper.getString('blocks-widgets')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>view_agenda</i>
          </span>
        </Tooltip>
      </Link>
    )
    if(current.coTypeId) {
      actions.push(
        <Link to={this.baseRoute() + '/pages/' + current.id + '/cotypeblocks'} key={current.id + '-cotypeblocks'}>
          <Tooltip title={Helper.getString('cotypeblocks-widgets')} placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>ballot</i>
            </span>
          </Tooltip>
        </Link>
      )
    }
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})

const pageActionsFieldDef = {
  formType: 'edit',
  steps: [
    {
      fields: [
        {
          hint: 'Action',
          key: 'action',
          label: 'Action',
          type: 'radio',
          options: [
            { key: 'CLONE_PAGE', label: 'CLONE_PAGE' },
          ],
          defaultValue: 'CLONE_PAGE',
          placement: 'left',
          // eslint-disable-next-line camelcase
          v_required: { value: true, err: 'Action required' }
        }
      ]
    }
  ]
}