import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppStyles } from '../../../styles/AppTheme';
const vsprintf = require('sprintf-js').vsprintf

export class CoTypesRead extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('cotypes')
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('cotypes') },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['cotypes'], { orgId: this.orgId, pb: 'fieldDef' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            {/* <br/>
            <span className={css(Styles.spanValue)}>Helper.formatTimestamp(current.createdTs)</span> */}
            <br/>
          </div>
        )
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.noAdd = true
    this.emptyText = vsprintf('No "%s" have been configured for you', [Helper.getString('cotypes')])
    // this.headerActions = [
    //   {
    //     label: 'Refresh',
    //     icon: 'refresh',
    //     onClick: () => this.fetchItems()
    //   },
    //   {
    //     label: 'New Custom Oject',
    //     onClick: () => this.openModalForAdd()
    //   },
    // ]
  }

  onFetchSuccess(results) {
    this.fieldDef = this.formDefinition = results[0].fieldDef
    this.setState({
      items: results[0].items,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions(current) {
    const actions = []
    actions.push(
      <Link to={this.baseRoute() + '/cos/' + current.id + '/items'} key={current.id + '-items'}>
        <Tooltip title={Helper.getString('cos')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>dataset</i>
          </span>
        </Tooltip>
      </Link>
    )
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 16
  }
})