import React, { } from 'react'
import { css, StyleSheet } from 'aphrodite';
import { Grid } from '@material-ui/core';

import { BaseEntityPage } from '../BaseEntityPage';
import { ApiHelper } from '../../helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../helpers/Helper';
import { SubEntityCard } from '../SubEntity/SubEntityCard';
import { UiHelper } from '../../helpers/UiHelper';
import { Controller } from '../../Controller';
import { ImageUploaderWithAssetPicker } from '../../../components/ImageUploaderWithAssetPicker';

function getTopicClusterId(topics, blogId) {
  const selectedTopic = topics.find(t => t.blogs && t.blogs.includes(blogId))
  return selectedTopic ? selectedTopic.id : '';
}

function getContentPlanFieldDef(topics, topicClusterId) {
  const clusterOptions = topics.map(t => ({
    key: t.id,
    label: `${t.topic}: ${t.title}`,
  }));

  return {
    steps: [
      {
        fields: [
          {
            hint: 'Topic cluster',
            key: 'topicClusterId',
            label: 'Topic cluster',
            type: 'select',
            options: clusterOptions,
            placement: 'left',
            value: topicClusterId,
            // eslint-disable-next-line camelcase
            v_required: { value: false, err: 'Topic cluster not required' },
          },
        ],
      },
    ]
  }
}

async function updateTopic(orgId, clusterId, blogs) {
  return ApiHelper.callAwait({
    method: 'PUT',
    jsonBody: { entityAction: 'edit', entityAttrName: 'topics', id: clusterId, blogs},
    endPoint: ApiHelper.makeUrlPath2({ ms: AppConfig.CONTENT_MS.ENDPOINT, paths: ['contents', orgId], queryParams: {} }),
  });
}

async function updateTopics(orgId, blogId, topicToRemoveBlog, topicToAddBlog) {
  if (topicToRemoveBlog) {
    const blogs = [...topicToRemoveBlog.blogs].filter(b => b !== blogId);
    await updateTopic(orgId, topicToRemoveBlog.id, blogs);
  }
  if (topicToAddBlog) {
    const blogs = [...topicToAddBlog.blogs].concat(blogId);
    await updateTopic(orgId, topicToAddBlog.id, blogs);
  }
}

export class Blog extends BaseEntityPage {
  constructor(props, options) {
    super(props)
    this.creatorPurposeOne = options.creatorPurposeOne
    this.pageTitle = Helper.getString('blog')
    this.pageKey = 'blog'
    this.noAdd = true
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.prodId = this.props.match.params.id
    this.emptyText = ' ' // space
    this.apiSpecForImageUploader = {}
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('blogs'), to: this.baseRoute() + '/blogs' },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      {
        key: 'item',
        method: 'GET', endPoint: ApiHelper.makeUrlPath(['blogs', this.prodId], {
          orgId: this.orgId,
          pb: 'fieldDef,fieldDefSEO,fieldDefSummary,fieldDefContentText'
        })
      },
      { key: 'tags', method: 'GET', endPoint: ApiHelper.makeUrlPath(['btags'], { orgId: this.orgId, maxPages: 5 }) },
      { key: 'lists', method: 'GET', endPoint: ApiHelper.makeUrlPath(['blists'], { orgId: this.orgId }) },
      { key: 'content', method: 'GET', paths: ['contents', this.orgId], queryParams: { groupId: this.orgId, pb: 'fieldDefContentTopicsEntity', limit: 1 }, ms: AppConfig.CONTENT_MS.ENDPOINT, },
    ]
    this.apiData = {
    }
    this.headerActions = [{
      label: 'Edit Content',
      icon: 'edit',
      onClick: () => Controller.get().pushRoute('content/' + this.prodId)
    }]
    this.state.disableEdit = false;
    this.customFormComponents = {
      imageUpload: {
        props: { orgId: this.orgId },
        comp: ImageUploaderWithAssetPicker
      }
    }
  }

  onFetchSuccess(results) {
    console.log('onFetchSuccess:', results)
    const item = this.getResult(results, 'item')

    const stateField = item.fieldDef.steps[0].fields.find(i => i.key === 'state');
    if (stateField) {
      stateField.options = stateField.options.filter(i => i.key !== 'GENERATING');
    }

    const disableEdit = item.state === 'GENERATING';
    if (disableEdit) {
      this.headerActions = []
    }

    this.pageTitle = item.title
    this.content = this.getResult(results, 'content')
    this.topics = Object.values(this.content.topics || {});

    item.topicClusterId = getTopicClusterId(this.topics, item.id);

    this.fieldDef = item.fieldDef
    this.fieldDefSEO = item.fieldDefSEO
    this.fieldDefSummary = item.fieldDefSummary
    this.fieldDefContentText = item.fieldDefContentText
    this.fieldDefContentPlan = getContentPlanFieldDef(this.topics, item.topicClusterId);

    this.formDefinition = this.fieldDef

    this.lists = this.getResult(results, 'lists')
    if (this.lists) {
      UiHelper.populateOptions(this.fieldDef, this.lists, 'listIds')
    }

    this.tags = this.getResult(results, 'tags')
    if (this.tags) {
      UiHelper.populateOptions(this.fieldDef, this.tags, 'tagIds')
    }

    this.setState({
      item: item,
      disableEdit,
    })
  }

  closeImageUploader = () => {
    this.setState({
      imageModelOpen: false,
      currentItem: {},
    })
    this.fetchItems()
  }

  handleUploadSuccess = () => {
    this.apiSpecForImageUploader = {}
    this.closeImageUploader()
  }

  renderAboveTable() {
    return (
      <div className={css(Styles.containerNarrow)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Main'} formDefinition={this.formDefinition}
              item={this.state.item}
              openModalForEdit={this.openModalForEdit.bind(this)}
              noEdit={this.state.disableEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Summary'} formDefinition={this.fieldDefSummary}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefSummary)}
              noEdit={this.state.disableEdit}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <SubEntityCard
              title={'Content'} formDefinition={this.fieldDefContentText}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefContentText)}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'SEO'} formDefinition={this.fieldDefSEO}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefSEO)}
              noEdit={this.state.disableEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubEntityCard
              title={'Content Plan'} formDefinition={this.fieldDefContentPlan}
              item={this.state.item}
              openModalForEdit={(current) => this.openModalForEdit(current, this.fieldDefContentPlan)}
              noEdit={this.state.disableEdit}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: {},
      apiEndPoint: ApiHelper.makeUrlPath(['blogs', current.id]),
    });
  }

  customSubmit(options) {
    const { formType, formData, caller } = options
    console.log('customSubmit:', formData)
    if (formType !== 'edit' || !formData || !formData.topicClusterId) {
      return caller.submit.call(caller, formData, true);
    }

    const blogId = this.state.item.id
    const topicToRemoveBlog = this.topics.find(t => t.blogs.includes(blogId))
    const topicToAddBlog = this.topics.find(t => t.id === formData.topicClusterId)
    updateTopics(this.orgId, blogId, topicToRemoveBlog, topicToAddBlog)
      .then(() => {
        this.setState({ modalOpen: false })
        this.fetchItems()
      })
      .catch(err => {
        const errorMessage = Helper.getErrorMsg(err)
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
      })
  }
}

const Styles = StyleSheet.create({
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
  container: {
    textAlign: 'center',
    maxWidth: AppTheme.viewPort,
    marginBottom: 20,
    width: '100%',
  }
})