import moment from 'moment';
import { Strings } from './Strings';
import { AppConfig } from '../../AppConfig';
import { AWSAmplifyHelper } from './AWSAmplifyHelper';

export class Helper {
  static HOUR_MS = 3600 * 1000
  static DAY_MS = 24 * 3600 * 1000
  static MILLISECOND_FOR_DAY = 24 * 60 * 60 * 1000

  static inArray(arr, value) {
    if (!arr) return false
    return arr.indexOf(value) >= 0 ? true : false
  }
  static getString(key) {
    return Strings.getString(key) || key
  }
  static setLanguage(lang) {
    Strings.setLanguage(lang)
    // if (['ar'].indexOf(lang) >= 0) {
    //   I18nManager.forceRTL(true)
    // } else {
    //   I18nManager.forceRTL(false)
    // }
  }

  static validEmail(email) { //TODO: why?
    const result = email.replace(/\s/g, '')
    if (!/\S+@\S+\.\S+/.test(result)) {
      return false
    }
    return result.toLowerCase()
  }

  static getLanguage() {
    return Strings.getLanguage();
  }

  static getLanguageText(data, key) {
    const language = Strings.getLanguage();
    return data[key + '_' + language] || data[key];
  }
  static encodeQueryString = (data) => {
    const ret = [];
    for (const d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
  }
  static formatName(item) {
    let name = '';
    if (!item) {
      return name;
    }
    if (item.nametitle) {
      name += item.nametitle
      name += ' '
    }
    if (item.givenname) {
      name += item.givenname
      name += ' '
    }
    if (item.middlename) {
      name += item.middlename
      name += ' '
    }
    if (item.familyname) {
      name += item.familyname
    }
    return name;
  }
  static formatUserEmail(item) {
    const name = Helper.formatName(item)
    const email = item.email
    return (name || '').trim() + (email ? ` <${email}>` : '')
  }
  static getErrorMsg(error) {
    let message;
    if (typeof error === 'string') {
      message = error;
    }
    if (!message) {
      if (error) {
        if (error.original && error.original.bodyJson) {
          const body = error.original.bodyJson;
          if (body && body.code && body.message) {
            message = body.message;
          }
        }
        if (!message) {
          message = error.message;
        }
        if (!message) {
          message = error.description;
        }
      }
    }
    return message || Helper.getString('customerCareErr');
  }
  static showProgressModal(caller) {
    caller.setState({ progressModelOpen: true });
  }
  static hideProgressModal(caller) {
    caller.setState({ progressModelOpen: false });
  }
  static formatTimestamp(utcTimestamp) {
    return moment.utc(utcTimestamp).local().format('Do MMM YYYY, h:mm a');
  }
  static formatDate(utcTimestamp) {
    return moment.utc(utcTimestamp).local().format('Do MMM YYYY');
  }
  static formatDate2(options) {
    const { utcTimestamp, zone, format } = options
    return moment.unix(utcTimestamp / 1000).tz(zone || AppConfig.DEFAULT_ZONEINFO).format(format || 'DD-MMM-YYYY')
  }
  static populateFormDefinitionValues(formDefinition, values) {
    for (const field of formDefinition.steps[0].fields) {
      if (values.__STAGED && field.key in values.__STAGED) {
        field.pendingApproval = true
        Helper.assignFieldValue(field, values.__STAGED)
      } else if (field.key in values) {
        Helper.assignFieldValue(field, values)
      } else {
        delete field.value;
        delete field.link;
      }
    }
  }
  static assignFieldValue(field, values) {
    field.value = values[field.key]
    if (field.type === 'radio' && Array.isArray(field.value)) {
      if (field.value.length > 0) {
        field.value = field.value[0]
      } else {
        delete field.value
      }
    }
    if (field.linkName) {
      field.link = values[field.linkName]
    }
  }
  static deleteFormDefinitionValues(formDefinition) {
    for (const field of formDefinition.steps[0].fields) {
      delete field.value;
      if (field.imageUpload === true) { //retained for backward compatibility
        field.link = null;
      }
      if (field.subType === 'image') {
        field.link = null;
      }
      if (field.documentUpload === true) { //retained for backward compatibility
        field.link = null;
      }
      if (field.subType === 'document') {
        field.link = null;
      }
    }
  }
  static validateAndFormatEmail(email) {
    const result = email.replace(/\s/g, '')
    if (!/\S+@\S+\.\S+/.test(result)) {
      throw new Error('Invalid email');
    }
    return result.toLowerCase()
  }
  static formatCurrency(value, currency) {
    return Helper.getLanguage() === 'ar'
      ? (currency || AppConfig.DEFAULT_CURRENCY) + ' ' + String(Number(value / 100).toFixed(2))
      : String(Number(value / 100).toFixed(2)) + ' ' + (currency || AppConfig.DEFAULT_CURRENCY);
  }
  static dictToArray(dict) {
    const arr = []
    if (!dict) {
      return arr
    }
    Object.keys(dict).forEach(k => {
      arr.push(dict[k])
    })
    return arr
  }
  static itemsToMap(items, idName) {
    const itemMap = {}
    idName = idName || 'id'
    for (const i of items) {
      itemMap[i[idName]] = i
    }
    return itemMap;
  }
  static sortDictArray(docs, attr) {
    const attrName = attr || 'order'
    return docs.sort((a, b) => {
      if (!a[attrName]) {
        return -1
      }
      if (!b[attrName]) {
        return 1
      }
      return a[attrName].localeCompare(b[attrName])
    })
  }
  static sortDictArrayRev(docs, attr) {
    const attrName = attr || 'order'
    return docs.sort((a, b) => {
      if (!a[attrName]) {
        return 1
      }
      if (!b[attrName]) {
        return -1
      }
      return b[attrName].localeCompare(a[attrName])
    })
  }

  static processCats(cats, listRootsAlso) {
    const catsById = Helper.itemsToMap(cats, 'id')
    const catsWhichAreParents = {}
    cats.forEach(i => {
      i.parents = []
      let k = i
      let depth = 0
      while (!k.parentId.startsWith('ROOT')) {
        catsWhichAreParents[k.parentId] = k // Has atleast one child
        i.parents.unshift(k.parentId)
        k = catsById[k.parentId]
        if (!k) break
        depth += 1; if (depth >= 5) break // loop braker - just in case
      }
    })

    const leafCats = []
    cats.forEach(i => {
      i.fullPath = ''
      i.parents.forEach(p => {
        if (!catsById[p]) return
        i.fullPath += catsById[p].title + '/'
      })
      i.fullPath += i.title

      i.titleOriginal = i.title
      i.title = i.fullPath
      if (!(i.id in catsWhichAreParents)) {
        leafCats.push(i)
      } else if (listRootsAlso) {
        leafCats.push(i)
      }
    })
    return Helper.sortAscending(leafCats, 'title')
  }

  static sortAscending(arr, key) {
    key = key || 'order'
    return arr.sort((a, b) => { return a[key].localeCompare(b[key]) })
  }

  static pretty(obj) {
    return JSON.stringify(obj, null, 2) + '\n';
  }

  static currentTs() {
    return Math.floor(new Date()); // Millisecs since epoch
  }

  static promisify(f) {
    const util = require('util')
    return util.promisify(f)
  }

  static selectDict(names, src) {
    const dest = {}
    const ns = names.split(',').map(v => v.trim())
    ns.forEach(n => {
      if (n in src) {
        dest[n] = src[n]
      }
    })
    return dest
  }

  static ellipsis(input, maxLength = 30) {
    if (!input) return input
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input
  }

  static fieldDefResolve(options) {
    console.log('fieldDefResolve:', options)
    const { def, values, key } = options
    let resolved = values[key]
    if (!def) return resolved
    def.steps[0].fields.forEach(field => {
      if (field.key !== key) return
      if (!field.options) return
      const optionsByKey = Helper.itemsToMap(field.options, 'key')
      const value = values[key]
      if (value) {
        if (field.type === 'radio') {
          const option = optionsByKey[value]
          if (option) {
            resolved = option.label || option.text || option.title
            return
          }
        } else if (field.type === 'check_box') {
          const resolves = []
          value.forEach(v => {
            const option = optionsByKey[v]
            if (option) resolves.push(option.label || option.text || option.title)
          })
          if (resolves.length > 0) {
            resolved = resolves.join(', ')
            return
          }
        }
      }
    })
    return resolved
  }

  static abbrNum(number) {
    // if (number >= 100000) {
    //   return (number / 100000).toFixed(1) + 'L'
    // } else if (number >= 1000) {
    //   return Math.round(number / 1000) + 'K'
    // }
    // return Math.round(number)
    return Math.round(number).toLocaleString('en-IN')
  }

  static makeFieldsCopy = (sourceFields, options) => {
    const fieldsCopy = {
      formType: options.formType,
      steps: [
        {
          fields: []
        }
      ]
    }
    for (const f of sourceFields) {
      const fDetail = {}
      for (const key in f) {
        fDetail[key] = f[key]
      }
      fieldsCopy.steps[0].fields.push(fDetail)
    }
    return fieldsCopy
  }

  static amplifyEventPageView(options) {
    AWSAmplifyHelper.eventPageView(options)
  }

  static amplifyEvent(options) {
    AWSAmplifyHelper.event(options)
  }

  static getYoutubeVideoId(ytLink) {
    const Url = require('url-parse')
    const url = new Url(ytLink, true)
    let videoId
    if(url.host !== 'www.youtube.com' && url.host !== 'youtu.be') return videoId
    if (url.hostname.endsWith('.be')) {
      videoId = url.pathname.substring(1)
    } else {
      videoId = url.query.v
    }
    return videoId
  }

  static extractAttrs(crmContactAttrs, attrKey) {
    return ((crmContactAttrs && crmContactAttrs[attrKey]) || '').split(',').map(t => t.trim()).filter(a => !!a)
  }
  static loadAttrOptions(fieldDef, crmContactAttrs, attrKey, fieldKey) {
    if (!fieldKey) fieldKey = attrKey
    const extractedAttrs = this.extractAttrs(crmContactAttrs, attrKey)
    const field = fieldDef.steps[0].fields.find(f => f.key === fieldKey)
    if (field) field.options = extractedAttrs.map(t => ({key: t, label: t}))
  }
  static getField(fieldDef, attrKey) {
    return fieldDef.steps[0].fields.find(f => f.key === attrKey)
  }
  static renderHtmlText(richText) {
    try {
      const parsedText = JSON.parse(richText)
      if (parsedText.__azformatted) {
        const draftToHtml = require('draftjs-to-html');
        return draftToHtml(parsedText.__azformatted)
      }
    } catch (e) { console.log('renderHtmlText error: ', e) }
    return ''
  }
  static renderPlainText(richText) {
    try {
      const parsedText = JSON.parse(richText)
      if (parsedText.__azformatted) {
        const entityMap = parsedText.__azformatted.entityMap;
        return parsedText.__azformatted.blocks.reduce((p, c, i) => {
          let entityText = '';
          const prefix = c.type === 'unordered-list-item' ? '- ' : '';
          if (c.entityRanges.length) {
            const entityIndex = c.entityRanges[0].key;
            const entity = entityMap[entityIndex];
            if (entity.type === 'LINK' && entity.data) {
              entityText = ': ' + entity.data.url || ''
            }
          }
          return (i === 0 ? '' : '\n') + p + prefix + c.text + entityText
        }, '')
      }
    } catch (e) { console.log('renderPlainText error: ', e) }
    return ''
  }
  static replaceRichText(richText, templateValues) {
    try {
      const richTextCopy = JSON.parse(richText)
      richTextCopy.__azformatted.blocks = richTextCopy.__azformatted.blocks.map(b => this.replaceRichTextBlock(b, templateValues));
      Object.values(richTextCopy.__azformatted.entityMap || {}).forEach(e => {
        if (e.data && e.data.url) {
          e.data.url = this.replaceText(e.data.url, templateValues);
        }
      });
      return JSON.stringify(richTextCopy);
    } catch (err) {
      console.error(err)
    }
    return richText || ''
  }
  static replaceRichTextBlock(block, templateValues) {
    const blockCopy = JSON.parse(JSON.stringify(block));
    let replacementsDiff = 0;
    blockCopy.text = blockCopy.text.replace(/{{([a-zA-Z][a-zA-Z0-9_().]*)\s*(,\s*([^{}]*))?}}/g, (...args) => {
      const fullMatch = args[0]
      const match = args.length >= 2 ? args[1] : '';
      let replacement = args.length >= 4 ? args[3] : args[0];
      if (match && match in templateValues) {
        replacement = templateValues[match];
      }
      const matchStart = args[4] + replacementsDiff;
      const matchLength = fullMatch.length
      const matchEnd = matchStart + matchLength - 1;

      if (replacement) {
        const replacementStart = matchStart;
        const replacementEnd = replacementStart + replacement.length - 1;

        const lengthDiff = replacement.length - matchLength;

        const listOfRanges = [blockCopy.inlineStyleRanges, blockCopy.entityRanges];
        listOfRanges.forEach(ranges => {
          ranges.forEach(range => {
            if (range.length <= 0) return;

            let rangeStart = range.offset;
            let rangeEnd = rangeStart + range.length - 1;

            if (matchStart > rangeEnd) {
              // case when range is before the match - no op
            } else if (matchEnd < rangeStart) {
              // case when range is after the match
              rangeStart += lengthDiff;
              rangeEnd += lengthDiff;
            } else {
              // case when range and match overlap
              // in this case, we apply the range to the entire replacement
              rangeStart = Math.min(rangeStart, replacementStart)
              rangeEnd = Math.max(rangeEnd, matchEnd) + lengthDiff;
            }

            range.offset = rangeStart;
            range.length = rangeEnd - rangeStart + 1;
          })
        })

        replacementsDiff += lengthDiff;

        return replacement;
      }
      return args[0];
    });
    return blockCopy;
  }
  static mergeRichText(richText1, richText2) {
    try {
      const parsedText1 = richText1 ? JSON.parse(richText1) : ''
      const parsedText2 = richText2 ? JSON.parse(richText2) : ''
      const mergedRichText = parsedText1
      if (mergedRichText.__azformatted && parsedText2.__azformatted && mergedRichText.__azformatted.blocks && parsedText2.__azformatted.blocks) {
        const newBlocks = JSON.parse(JSON.stringify(parsedText2.__azformatted.blocks))
        if (mergedRichText.__azformatted.entityMap && parsedText2.__azformatted.entityMap) {
          let lastEntityKeyIndex = Object.keys(mergedRichText.__azformatted.entityMap).reduce((p, c) => Number.parseInt(c) > p ? Number.parseInt(c) : p, -1)
          const newEntityMap = {}
          Object.keys(parsedText2.__azformatted.entityMap).forEach(entityKey => {
            lastEntityKeyIndex = lastEntityKeyIndex + 1
            const newKey = `${lastEntityKeyIndex}`
            newEntityMap[newKey] = parsedText2.__azformatted.entityMap[entityKey]
            parsedText2.__azformatted.blocks.forEach((oldBlock, blockIndex) => {
              const newBlock = newBlocks[blockIndex]
              oldBlock.entityRanges.forEach((oldRange, rangeIndex) => {
                const newRange = newBlock.entityRanges[rangeIndex]
                if (oldRange.key == entityKey) {
                  newRange.key = lastEntityKeyIndex
                }
              })
            })
          })
          Object.assign(mergedRichText.__azformatted.entityMap, newEntityMap)
        }
        mergedRichText.__azformatted.blocks.push(...newBlocks)
      }
      return JSON.stringify(mergedRichText)
    } catch (e) { console.log('mergeRichText error: ', e) }
    return richText1
  }
  static htmlToRichText(htmlText) {
    try {
      const { convertFromHTML, convertToRaw, ContentState } = require('draft-js')
      const blocksFromHTML = convertFromHTML(htmlText)
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      return JSON.stringify({ __azformatted: convertToRaw(contentState) })
    } catch (e) { console.log('htmlToRichText error: ', e) }
    return ''
  }
  static extractEmailAddress(rawEmailAddress) {
    const re = /[^< ]+(?=>)/g;
    const matches = rawEmailAddress.match(re)
    return (matches && matches.length > 0) ? matches[0] : rawEmailAddress
  }
  static isEmailEqual(addr1, addr2) {
    return this.extractEmailAddress(addr1) === this.extractEmailAddress(addr2)
  }
  static replaceText(text, templateValues) {
    return text.replace(/{{([a-zA-Z][a-zA-Z0-9_().]*)\s*(,\s*([^{}]*))?}}/g, (...args) => {
      const match = args.length >= 2 ? args[1] : '';
      const fallbackVal = args.length >= 4 ? args[3] : args[0];
      if (match && match in templateValues) {
        return templateValues[match];
      }
      return fallbackVal || '';
    });
  }
  static addRichTextUrlQueryParams(richText, qs) {
    try {
      const richTextCopy = JSON.parse(richText)
      Object.values(richTextCopy.__azformatted.entityMap || {}).forEach(e => {
        if (e.data && e.data.url && qs) {
          const modUrl = new URL(e.data.url)
          Object.entries(qs).forEach(qse => modUrl.searchParams.set(qse[0], qse[1]))
          e.data.url = modUrl.href
        }
      });
      return JSON.stringify(richTextCopy);
    } catch (err) {
      console.log(err)
    }
    return richText
  }
  static addHtmlTextUrlQueryParams(htmlText, qs) {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlText, 'text/html');
      const anchors = doc.getElementsByTagName('a');
      for(let i = 0; i < anchors.length; i++) {
        try {
          const _url = new URL(anchors[i].getAttribute('href'))
          Object.entries(qs).forEach(qse => _url.searchParams.set(qse[0], qse[1]))
          anchors[i].setAttribute('href', _url.href);
        } catch (err) {
          console.log(err)
        }
      }
      return doc.documentElement.innerHTML
    } catch (err) {
      console.log(err)
    }
    return htmlText
  }
  static escapeHtml(unsafe)
  {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
  static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  static formatTimeFrom24to12Hour(timeString) {
    const [hourString, minute] = timeString.split(':');
    const hour = +hourString % 24;
    const suffix = hour < 12 ? ' AM' : ' PM';
    const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
    return `${formattedHour}:${minute}${suffix}`;
  }

  static getDaysOfWeekLong() {
    const daysOfWeekLong = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return daysOfWeekLong;
  }

  static getShortDayFromLong(day) {
    const daysOfWeekLong = Helper.getDaysOfWeekLong();
    if(daysOfWeekLong.includes(day)) {
      return day.substr(0, 3)
    }
    return ''
  }

  static debounce(func, delay) {
    let debounceTimer;
    return function(...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  }

  static yearMonthDayHour(timestamp, zoneinfo) {
    if (zoneinfo) {
      return moment(Number(timestamp)).tz(zoneinfo).format('YYYYMMDDHH');
    }
    return moment(Number(timestamp)).format('YYYYMMDDHH');
  }

  static yyyymmddToDate(yyyymmdd, zoneinfo) {
    const a = moment(yyyymmdd, 'YYYYMMDD');
    return a.toDate()
  }

  static generatePreviousMonthsOrDays(type, count) {
    const dates = [];
    let currentDate = moment();

    for (let i = 0; i < count; i++) {
      if (type === 'months') {
        dates.push(currentDate.format('MMM YYYY'));
        currentDate = currentDate.subtract(1, 'month');
      } else if (type === 'days') {
        dates.push(currentDate.format('MMM DD'));
        currentDate = currentDate.subtract(1, 'day');
      }
    }

    return dates.reverse();
  }

  static getHoursIn12HourFormat() {
    const hoursIn12HourFormat = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];
    return hoursIn12HourFormat;
  }

  static removeQueryParams(url) {
    try {
      const parsedUrl = new URL(url);
      return `${parsedUrl.hostname}${parsedUrl.pathname}`;
    } catch (error) {
      return url;
    }
  }

}
