import React, { Component } from 'react';

import { ApiHelper } from '../../helpers/ApiHelper';
import { AppHelper } from '../../../helpers/AppHelper';
import { Helper } from '../../helpers/Helper';
import { BaseEntityPage } from '../BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Controller } from '../../Controller';

import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { css, StyleSheet } from 'aphrodite';
import { UiHelper } from '../../helpers/UiHelper';
import { CustomBlogListsStyles } from '../../../styles/CustomStyles';
import { ImageUploaderWithAssetPicker } from '../../../components/ImageUploaderWithAssetPicker';

export class BlogLists extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('bloglists')
    this.pageKey = 'blogLists'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['blists'], { orgId: this.orgId, pb: 'fieldDefBlogList,fieldDefSEO' }) },
    ]
    this.tableHeadersMap = {
      Index: (current) => { return current.__index + 1 },
      Order: (current) => { return current.order },
      'Slug / ID': (current) => {
        return UiHelper.itemDetails(current)
      },
      Title: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.title}</span>
            <br />
            <span className={css(Styles.spanValue)}>{current.itype}</span>
          </div>
        )
      },
      Status: (current) => UiHelper.itemStatusContainer(current)
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId,
    }
    this.customFormComponents = {
      imageUpload: {
        props: { orgId: this.orgId },
        comp: ImageUploaderWithAssetPicker
      }
    }
  }

  onFetchSuccess(results) {
    this.fieldDefSEO = results[0].fieldDefSEO
    this.fieldDefBlogList = results[0].fieldDefBlogList
    this.fieldDef = this.fieldDefBlogList
    this.setState({
      items: Helper.sortDictArray(results[0].items),
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) {
      return ''
    }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['blists'], { orgId: this.orgId }),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['blists', current.id], { orgId: this.orgId }),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['blists', current.id], { orgId: this.orgId }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonSEO(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    actions.push(
      <Link to={this.baseRoute() + '/blists/' + current.id + '/blogs'} key={current.id + '-blists'}>
        <Tooltip title='Items in list' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>layers</i>
          </span>
        </Tooltip>
      </Link>
    )
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomBlogListsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})